import { CommonModule } from '@angular/common';
import { Component, HostBinding, Inject } from '@angular/core';
import { OnDestroyCleanup } from '@gamewaver/core';
import { takeUntil } from 'rxjs';
import { MentionService, MENTION_TOKEN } from './mention.service';
import { Mention } from './models';

@Component({
	selector: 'gw-mention',
	templateUrl: './mention.component.html',
	styleUrls: ['./mention.component.scss'],
	providers: [CommonModule]
})
export class MentionComponent extends OnDestroyCleanup {
	@HostBinding('class') className = 'mentions';
	options: Mention[];

	constructor(@Inject(MENTION_TOKEN) private mentionService: MentionService) {
		super();
		this.mentionService.mentions$.pipe(takeUntil(this.destroyed$)).subscribe(data => {
			this.options = data;
		});
	}

	onClick(option: Mention): void {
		this.mentionService.mentionOutput = option;
		this.mentionService.close();
	}
}
