import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@gamewaver/shared';
import { HeaderComponent } from './header.component';
import { MenuComponent } from './menu.component';
import { NavigationModule } from '@gamewaver/navigation';
import { NotificationsModule } from '@gamewaver/notifications';

@NgModule({
	declarations: [HeaderComponent, MenuComponent],
	imports: [CommonModule, SharedModule, NavigationModule, NotificationsModule],
	exports: [HeaderComponent, MenuComponent],
})
export class HeaderModule {}
