import { Injectable } from '@angular/core';
import { PagedData, RequestParams, IResponse, HttpClientService } from '@gamewaver/core';
import { UpdateUserCmd, User } from '@gamewaver/users';

@Injectable({
	providedIn: 'root',
})
export class UsersApiService {
	BASE_URL = 'users';

	constructor(private httpClient: HttpClientService) {}

	update(id: number, cmd: UpdateUserCmd): Promise<IResponse<User>> {
		return this.httpClient.put<IResponse<User>>(`${this.BASE_URL}/${id}`, cmd);
	}

	findAll(params?: RequestParams): Promise<IResponse<PagedData<User>>> {
		return this.httpClient.get<IResponse<PagedData<User>>>(`${this.BASE_URL}${this.httpClient.getParams(params)}`);
	}

	findOne(id: number): Promise<IResponse<User>> {
		return this.httpClient.get<IResponse<User>>(`${this.BASE_URL}/${id}`);
	}
}
