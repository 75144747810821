import { PagedData, OnDestroyCleanup, ScrollPositionService } from '@gamewaver/core';
import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, takeUntil, debounceTime } from 'rxjs';
import { AuthService } from '@gamewaver/auth';
import { SidebarStateService } from '@gamewaver/sidebar';
import { User } from '@gamewaver/users';
import { PostViewModel } from './models/post-view.model';
import { PostRoute } from './models/post.models';
import { PostsService } from './posts.service';
import { AddItem } from '@gamewaver/add-item';

@Component({
	selector: 'gw-posts',
	templateUrl: './posts.component.html',
})
export class PostsComponent extends OnDestroyCleanup implements AfterViewInit {
	posts$: Observable<PostViewModel[]>;
	posts: PagedData<PostViewModel>;
	user?: User;
	addItem?: AddItem;

	constructor(
		private route: ActivatedRoute,
		private postsService: PostsService,
		private scrollPositionService: ScrollPositionService,
		private authService: AuthService,
		private sidebarStateService: SidebarStateService
	) {
		super();
		combineLatest([this.route.params, this.route.queryParams])
			.pipe(takeUntil(this.destroyed$), debounceTime(10))
			.subscribe(([params, queryParams]) => {
				const route: PostRoute = { ...params };
				if (queryParams.query) {
					route.searchQuery = queryParams.query;
				}

				this.postsService.postRoute = route;
				this.sidebarStateService.postRoute = route;
			});

		this.authService.profile$.pipe(takeUntil(this.destroyed$)).subscribe(u => {
			this.user = u;
			this.addItem = u ? { isPost: true, minLength: 3, maxLength: 5000, userAvatar: u.avatar, userId: u.id } : undefined;
		});
		this.posts$ = this.postsService.items$;
	}

	ngAfterViewInit(): void {
		this.scrollPositionService.restorePostsScrollPos();
	}

	onScrollDown(): void {
		this.postsService.getMany();
	}
}
