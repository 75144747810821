import { Injectable } from '@angular/core';

export interface WordProperties {
	index: number;
	selectedText: string;
	wholeWord: string;
}

export enum ReplacementType {
	SingleWord = 'single_word',
	PartialWord = 'partial_word',
	MultupleWords = 'multiple_words',
}

@Injectable()
export class ToolbarHelperService {
	content: string;
	caretPos: number;
	private selectedText = '';

	setCaretPos(input: HTMLInputElement): void {
		if (input.selectionStart || input.selectionStart === 0) {
			this.caretPos = input.selectionStart;
		}
	}

	setSelectedText(input: HTMLInputElement): void {
		this.selectedText = '';

		if (input.selectionStart != input.selectionEnd && input.selectionStart && input.selectionEnd) {
			this.selectedText = this.content.substring(input.selectionStart, input.selectionEnd);
		}
	}

	newLineFormat(symbol: string): string {
		return (this.content || '') + '\n' + symbol;
	}

	formatVideoLink(): string {
		const directUrlRegex = /https?:\/\/(www.)?[a-zA-Z0-9.]{1,256}.[a-zA-Z0-9()]{1,6}[\/a-zA-Z0-9-_]+.(webm|mp4)/g;
		const youTubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
		const medalTvRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:medal\.tv))(\/clips\/)([a-zA-Z0-9]+)\/([a-zA-Z0-9]+)$/;
		const streamableRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:streamable\.com))\/([a-zA-Z0-9]+)$/;
		let input = this.selectedText;

		if (directUrlRegex.test(input)) {
			this.content = this.content.replace(input, '');
			input = `<video src="${input}" controls></video>`;

			return this.newLineFormat(input);
		} else if (youTubeRegex.test(input)) {
			this.content = this.content.replace(input, '');
			const youtubeMatches = input.match(youTubeRegex)!;
			input = `<div class="embed-container"><iframe src="https://www.youtube.com/embed/${youtubeMatches[5]}" frameborder="0" allowfullscreen></iframe></div>`;

			return this.newLineFormat(input);
		} else if (medalTvRegex.test(input)) {
			this.content = this.content.replace(input, '');
			const medaltvMatches = input.match(medalTvRegex)!;
			input = `<div class="embed-container"><iframe style='border: none;' src='https://medal.tv/clip/${medaltvMatches[5]}/${medaltvMatches[6]}?autoplay=0&muted=0&loop=0' allow='autoplay' allowfullscreen></iframe></div>`;

			return this.newLineFormat(input);
		} else if (streamableRegex.test(input)) {
			this.content = this.content.replace(input, '');
			const streamableMatches = input.match(streamableRegex)!;
			input = `<div class="embed-container"><iframe src="https://streamable.com/e/${streamableMatches[4]}?loop=0" frameborder="0" allowfullscreen></iframe></div>`;

			return this.newLineFormat(input);
		}

		return this.newLineFormat(`<div class="embed-container">\n{replace with video embed code}\n</div>\n`);
	}
}
