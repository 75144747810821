import { Component, HostBinding } from '@angular/core';
import { Observable, takeUntil, distinctUntilChanged } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { Notification } from './notification';
import { OnDestroyCleanup, LoadingService } from '@gamewaver/core';

@Component({
  selector: 'gw-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends OnDestroyCleanup {
	@HostBinding('class') className = 'notifications-container';
	@HostBinding(`class.align-start`) alignStart: boolean;

	notifications: Notification[];
	loadingSpinnerVisible$: Observable<boolean>;

	constructor(private notificationsService: NotificationsService, private loadingService: LoadingService) {
		super();
		this.loadingSpinnerVisible$ = this.loadingService.notificationsLoading$.pipe(distinctUntilChanged());
		this.notificationsService.items$.pipe(takeUntil(this.destroyed$)).subscribe(x => {
			this.notifications = x;
			this.alignStart = this.notifications.length > 0 ? true : false;
		});
	}

	onScrollDown(): void {
		this.notificationsService.getMany();
	}
}
