import { faMarkdown } from '@fortawesome/free-brands-svg-icons';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, OnChanges, ChangeDetectorRef } from '@angular/core';
import { PostCmd, postCategories, postVisibilities, PostsService } from '@gamewaver/posts';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { UserInfo, UserInfoContext, Selector, MentionDom } from '@gamewaver/shared';
import { Mention, TabOption, AddItem } from './models';
import { ToolbarHelperService } from './toolbar-helper.service';
import { MentionService } from './mention.service';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { debounceTime, takeUntil } from 'rxjs';
import { UserCardService } from '@gamewaver/user-card/user-card.service';
import { OnDestroyCleanup, TypedChange } from '@gamewaver/core';
import { CommentCmd, CommentsService } from '@gamewaver/comments';

@Component({
	selector: 'gw-add-item',
	templateUrl: './add-item.component.html',
	styleUrls: ['./add-item.component.scss'],
})
export class AddItemComponent extends OnDestroyCleanup implements OnInit, OnChanges {
	@Input() addItem: AddItem;
	@Output() cancelEditItem: EventEmitter<void> = new EventEmitter();
	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
	@ViewChild('textArea') textArea: ElementRef;
	@ViewChild(CdkOverlayOrigin, { read: ElementRef }) origin: ElementRef;

	itemForm: UntypedFormGroup;
	categories = postCategories;
	showActions: boolean;
	userInfoContext = UserInfoContext;
	tabOption = TabOption;
	activeTab = TabOption.Write;
	showToolbar = true;
	markdown = faMarkdown;
	categoryData: Selector = {
		label: 'Category',
		options: postCategories,
		required: true,
		name: 'category',
	};

	visibilityData: Selector = {
		label: 'Visibility',
		options: postVisibilities,
		required: true,
		name: 'visibility',
	};

	get userInfo(): UserInfo {
		return {
			id: this.addItem.userId!,
			avatar: this.addItem.userAvatar,
		};
	}

	@HostListener('mousedown')
	mousedown(): void {
		this.showActions = true;
	}

	constructor(
		private commentsService: CommentsService,
		private postsService: PostsService,
		private toolbarHelper: ToolbarHelperService,
		private changeDetectorRef: ChangeDetectorRef,
		private mentionService: MentionService,
		private userCardService: UserCardService,
	) {
		super();
	}

	ngOnInit(): void {
		this.content.valueChanges.pipe(debounceTime(500)).subscribe(x => {
			this.toolbarHelper.content = x;
			this.mentionService.setMentionInput(x, this.toolbarHelper.caretPos);
		});

		this.mentionService.mentionOutput$.pipe(takeUntil(this.destroyed$)).subscribe(this.processMention.bind(this));
	}

	ngOnChanges(changes: TypedChange<AddItemComponent>): void {
		const addItem = changes.addItem?.currentValue;

		if (addItem) {
			this.itemForm = new UntypedFormGroup({
				content: new UntypedFormControl(addItem.content || '', [
					Validators.minLength(addItem.minLength),
					Validators.maxLength(addItem.maxLength),
					Validators.required,
				]),
			});

			this.categoryData.value = addItem.category;
			this.visibilityData.value = addItem.visibility;
			this.showActions = addItem.content ? true : false;
			this.toolbarHelper.content = addItem.content!;
		}
	}

	get content(): AbstractControl {
		return this.itemForm.get('content')!;
	}

	get category(): AbstractControl {
		return this.itemForm.get('category')!;
	}

	get visibility(): AbstractControl {
		return this.itemForm.get('visibility')!;
	}

	onSubmit(): void {
		if (this.addItem.isPost) {
			const postCmd: PostCmd = {
				content: this.content.value,
				category: this.category.value,
				visibility: this.visibility.value,
			};

			this.addItem.id ? this.postsService.edit(postCmd, this.addItem.id) : this.postsService.create(postCmd);
		} else {
			const commentCmd: CommentCmd = {
				content: this.content.value,
			};

			this.addItem.id ? this.commentsService.edit(commentCmd, this.addItem.id) : this.commentsService.create(commentCmd);
		}

		this.showActions = false;
		this.resetForm();
	}

	onCancel(): void {
		this.resetForm();
		this.showActions = false;
		this.cancelEditItem.emit();
		this.textArea.nativeElement.style.height = '36px';
	}

	getErrorMessage(): string {
		if (this.content.errors?.required) {
			return 'Content is required';
		} else if (this.content.errors?.minlength || this.content.errors?.maxlength) {
			return `Content must be between ${this.addItem.minLength} and ${this.addItem.maxLength} characters long`;
		}

		return '';
	}

	onAddedEmoji(emoji: EmojiData): void {
		const splitted = (this.content.value as string).split('');
		splitted.splice(this.toolbarHelper.caretPos, 0, emoji.native!);
		this.itemForm.patchValue({
			content: splitted.join(''),
		});
	}

	onUpload(imageLink: string): void {
		this.itemForm.patchValue({
			content: this.content.value + `\n![](${imageLink})\n`,
		});
	}

	onClickTextArea(input: HTMLInputElement): void {
		this.toolbarHelper.setCaretPos(input);
		this.toolbarHelper.setSelectedText(input);
	}

	onTextFormatted(text: string): void {
		this.itemForm.patchValue({ content: text });
	}

	onLoadedSelecotor(control: UntypedFormControl, name: string): void {
		this.itemForm.addControl(name, control);
		// adding detect changes here because at this point the view has been build and check for changes
		// has been runned, but with add controll we are changing code value, therefore resulting in ExpressionChangedAfterItHasBeenCheckedError
		this.changeDetectorRef.detectChanges();
	}

	onKeyPress(event: KeyboardEvent, input: HTMLInputElement ): void {
		const splitted = (this.content.value as string)?.split('') || [];
		this.toolbarHelper.setCaretPos(input);
		const shouldOpenMention  = splitted[this.toolbarHelper.caretPos -1] === ' ' || this.toolbarHelper.caretPos === 0;

		if (shouldOpenMention && event.key === '@') {
			this.mentionService.setOrigin(this.origin);
		}
	}

	onMention(data: MentionDom): void {
		this.userCardService.open(data);
	}

	private resetForm(): void {
		this.itemForm.removeControl(this.categoryData.name);
		this.itemForm.removeControl(this.visibilityData.name);
		this.formGroupDirective.resetForm();
		this.content.setValue('');
	}

	private processMention(mention: Mention): void {
		const text = this.mentionService.setSelectedMention(this.content.value, this.toolbarHelper.caretPos, mention);
		this.itemForm.patchValue({ content: text });
	}
}
