import { NgModule } from '@angular/core';
import { AddItemModule } from '@gamewaver/add-item';
import { CommentsModule } from '@gamewaver/comments';
import { SharedModule } from '@gamewaver/shared';
import { UserCardModule } from '@gamewaver/user-card';
import { PostPageComponent } from './post-page.component';
import { PostPageResolver } from './post-page.resolver';
import { PostComponent } from './post.component';
import { PostsComponent } from './posts.component';

@NgModule({
  declarations: [PostPageComponent, PostsComponent, PostComponent],
  imports: [
	SharedModule,
	CommentsModule,
	AddItemModule,
	UserCardModule,
  ],
  exports: [PostPageComponent, PostsComponent, PostComponent],
  providers: [PostPageResolver],
})
export class PostsModule { }
