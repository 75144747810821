import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isArray } from 'lodash-es';
import { AuthParams, PostParams, SidebarParams, UsersParams } from './url-params';

@Injectable()
export class UrlHelperService {
	login = `${AuthParams.Auth}/${AuthParams.Login}`;

	constructor(private router: Router) {}

	profile(id: number): string {
		return this.router.createUrlTree([UsersParams.Users, UsersParams.Profile, id]).toString();
	}

	post(id: number): string {
		return this.router.createUrlTree([PostParams.Post, id]).toString();
	}

	category(id: string, sortType?: string, sortTime?: string): string {
		const transformed = id
			.split(/[\s_]+/)
			.map(x => x.toLowerCase())
			.join('-');

		return this.router.createUrlTree([SidebarParams.Category, transformed, sortType, sortTime].filter(x => !!x)).toString();
	}

	archive(input: string, isYear?: boolean): string {
		return this.router.createUrlTree([`${isYear ? 'year' : 'month'}-${input}`]).toString();
	}

	url(segment: string | string[]): string {
		if (isArray(segment)) {
			return this.router.createUrlTree([...segment]).toString();
		}

		return this.router.createUrlTree([segment]).toString();
	}
}
