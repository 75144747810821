<div class="menu">
	<ng-container *ngFor="let item of menuItems">
		<a mat-button *ngIf="!item.children" [routerLink]="[item.path]">
			<mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
			<span class="menu-item-name">{{ item.name }}</span>
		</a>

		<!-- Children menu -->
		<ng-container *ngIf="item.children">
			<button mat-button [matMenuTriggerFor]="menu" class="menu-item-name">{{ item.name }}</button>
			<mat-menu #menu="matMenu">
				<ng-container *ngFor="let child of item.children">
					<a mat-menu-item [routerLink]="[item.route, child.path]">
						<mat-icon *ngIf="child.icon">{{ child.icon }}</mat-icon>
						<span class="menu-item-name">{{ child.name }}</span>
					</a>
				</ng-container>
			</mat-menu>
		</ng-container>
		<!-- Children menu -->
	</ng-container>

	<ng-container *ngIf="(user$ | async) as user; else notLogged">
		<button mat-button [matMenuTriggerFor]="menuUser">
			<span class="menu-item-name">{{ user.username }}</span>
		</button>

		<button mat-icon-button (click)="onNotification()" cdkOverlayOrigin *ngIf="{ count: notificationsCount$ | async } as obs">
			<mat-icon
				[matBadgeHidden]="obs.count === 0"
				[matBadge]="obs.count"
				matBadgeColor="warn"
				matBadgeSize="small"
				class="notifications"
				[fontSet]="!!(dialogOpen$ | async) ? 'material-icons' : 'material-icons-outlined'"
				>notifications</mat-icon
			>
		</button>

		<mat-menu #menuUser="matMenu">
			<a mat-menu-item [routerLink]="['users', 'profile', user!.id]">
				<mat-icon>account_circle</mat-icon>
				<span class="menu-item-name">Profile</span>
			</a>
			<button mat-menu-item (click)="onLogout()">
				<mat-icon>power_settings_new</mat-icon>
				<span class="menu-item-name">Logout</span>
			</button>
		</mat-menu>
	</ng-container>

	<ng-template #notLogged>
		<button mat-button (click)="openLoginDialog()">
			<mat-icon>power_settings_new</mat-icon>
			<span class="menu-item-name">Login</span>
		</button>
	</ng-template>
</div>
