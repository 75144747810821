import { SidebarItem } from './sidebar-view.models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnDestroyCleanup } from '@gamewaver/core';

@Component({
	selector: 'gw-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss', './sidebar.component.scss'],
})
export class CategoryComponent extends OnDestroyCleanup {
	@Input() categories: SidebarItem[];
	@Output() selected: EventEmitter<SidebarItem> = new EventEmitter();

	onClick(category: SidebarItem): void {
		this.selected.emit(category);
	}
}
