import { Injectable } from '@angular/core';
import { RouteTag } from './route-tag';

@Injectable({ providedIn: 'root' })
export class TagService {
	private _tag: RouteTag;
	private previousTag: RouteTag;
	private profileTabs = [RouteTag.ProfilePosts, RouteTag.ProfileComments, RouteTag.ProfileVotes];

	set tag(value: RouteTag) {
		if (this._tag !== value) {
			this.previousTag = this._tag;
			this._tag = value;
		}
	}

	get tag(): RouteTag {
		return this._tag;
	}

	get isProfileBackToHome(): boolean {
		return this.profileTabs.includes(this.previousTag) && this._tag === RouteTag.Home;
	}

	get isProfileTab(): boolean {
		return this.profileTabs.includes(this._tag);
	}
}
