import { Component, Output, EventEmitter, ViewChild, HostBinding, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';
import { EmojiEvent, EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalService } from '@gamewaver/core';

@Component({
	selector: 'gw-emoji',
	templateUrl: './emoji.component.html',
	styleUrls: ['./emoji.component.scss'],
})
export class EmojiComponent {
	@Output() emoji: EventEmitter<EmojiData> = new EventEmitter();
	@HostBinding('class') className = 'emoji';
	@ViewChild(CdkOverlayOrigin, { read: ElementRef }) origin: ElementRef;
	@ViewChild('templatePortalContent') templatePortalContent: TemplateRef<unknown>;

	templatePortal: TemplatePortal<any>;

	constructor(private portalService: PortalService<unknown>, private viewContainerRef: ViewContainerRef) {
		this.portalService.viewContainerRef = this.viewContainerRef;
	}

	addEmoji(ev: EmojiEvent): void {
		this.emoji.emit(ev.emoji);
		this.portalService.close();
	}

	openEmojiOverlay(): void {
		this.portalService.open({
			templateRef: this.templatePortalContent,
			margin: 120,
			offsetY: 230,
			disableScroll: true,
			origin: this.origin
		});
	}
}
