import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum SnackbarErrors {
	GetPosts = 'Get Posts Failed',
	GetPost = 'Get Post Failed',
	CreatePost = 'Create Post Failed',
	EditPost = 'Edit Post Failed',
	DeletePost = 'Delete Post Failed',

	GetComments = 'Get Comments Failed',
	CreateComment = 'Create Comment Failed',
	EditComment = 'Edit Comment Failed',
	DeleteComment = 'Delete Comment Failed',

	CreateVote = 'Create Vote Failed',
	DeleteVote = 'Delete Vote Failed',

	GetUser = 'Get User Failed',
	EditUser = 'Edit User Failed',
	GetUsersForMention = 'Getting Users For Mention Failed',

	Register = 'Registration Failed',
	Login = 'Login Failed',
	ForgotPassword = 'Forgot Password Failed',
	ResetPassword = 'Reset Password Failed',

	GetNotifications = 'Get Notifications Failed',
	EditNotification = 'Edit Notification Failed',
	DeleteNotification = 'Delete Notification Failed',
	GetNotificationCount = 'Get Notification Count Failed',
}

@Injectable()
export class SnackbarService {
	constructor(private snackBar: MatSnackBar) {}

	showInfo(message: string, duration: number = 5000): void {
		this.snackBar.open(message, 'CLOSE', {
			duration,
			panelClass: 'info',
		});
	}

	showSuccess(message: string, duration: number = 5000): void {
		this.snackBar.open(message, 'CLOSE', {
			duration,
			panelClass: 'success',
		});
	}

	showError(message: string, duration: number = 5000): void {
		this.snackBar.open(message, 'CLOSE', {
			duration,
			panelClass: 'error',
		});
	}

	handleFailure(error: any, message: SnackbarErrors): void {
		if (typeof error === 'string') {
			this.showError(`${message} ${error}`);
			throw new Error(error);
		} else if (error?.error?.message && error?.error?.failed) {
			this.showError(`${message} ${error.error.message}`);
			throw new Error(error.message);
		} else if (error instanceof TypeError) {
			this.showError(`${message} ${error.message}`);
			throw new Error(error.message);
		}
	}
}
