import { UntypedFormControl } from '@angular/forms';
import { SidebarItem } from './sidebar-view.models';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'gw-sort-by',
	templateUrl: './sort-by.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SortByComponent implements OnChanges {
	@Input() sorts: SidebarItem[];
	@Input() times: SidebarItem[];
	@Output() selected: EventEmitter<SidebarItem> = new EventEmitter();
	selectedType = new UntypedFormControl();
	selectedTime = new UntypedFormControl();

	ngOnChanges(): void {
		this.selectedType.setValue(this.sorts.find(x => x.active));
		this.selectedTime.setValue(this.times.find(x => x.active));
	}

	onType(): void {
		this.selected.emit(this.selectedType.value);
	}

	onTime(): void {
		this.selected.emit(this.selectedTime.value);
	}
}
