<mat-spinner [diameter]="40" *ngIf="!notifications; else withNotifications"></mat-spinner>

<ng-template #withNotifications>
	<div
		class="notifications"
		infiniteScroll
		(scrolled)="onScrollDown()"
		[infiniteScrollDistance]="1"
		[infiniteScrollContainer]="'.notifications-container'"
		[fromRoot]="true"
		*ngIf="notifications?.length > 0; else noNotifications"
	>
		<gw-notification [notification]="notification" *ngFor="let notification of notifications"></gw-notification>
	</div>
	<mat-spinner [diameter]="40" *ngIf="loadingSpinnerVisible$ | async"></mat-spinner>
</ng-template>

<ng-template #noNotifications>
	<div class="empty">You don't have notifications yet.</div>
</ng-template>
