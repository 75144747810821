import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnChanges } from '@angular/core';
import { RedirectHelperService } from '@gamewaver/navigation';
import { TypedChange } from '@gamewaver/core';

@Component({
	selector: 'gw-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
	@Input() searchTerm: string;
	searchTermControl = new UntypedFormControl('');

	constructor(private redirectHelper: RedirectHelperService) {}

	ngOnChanges(changes: TypedChange<SearchComponent>): void {
		if (changes.searchTerm?.currentValue) {
			this.searchTermControl.setValue(this.searchTerm);
		}
	}

	onSubmit(ev: Event): void {
		ev.preventDefault();
		ev.stopPropagation();
		if (this.searchTermControl.value) {
			this.redirectHelper.goToPage(`search?query=${this.searchTermControl.value}`);
		}
	}

	onClear(): void {
		this.searchTermControl.setValue('');
	}
}
