import { EmojiComponent } from './emoji.component';
import { AddItemComponent } from './add-item.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormattingHelpComponent } from './formatting-help.component';
import { SharedModule } from '@gamewaver/shared';
import { UploadComponent } from './upload.component';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarHelperService } from './toolbar-helper.service';
import { ToolbarButtonComponent } from './toolbar-button.component';
import { TabsComponent } from './tabs.component';
import { MentionComponent } from './mention.component';
import { MentionService } from './mention.service';
import { UploadService } from './upload.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
	declarations: [
		AddItemComponent,
		FormattingHelpComponent,
		UploadComponent,
		EmojiComponent,
		ToolbarComponent,
		ToolbarButtonComponent,
		TabsComponent,
		MentionComponent,
	],
	imports: [SharedModule, PickerModule],
	exports: [AddItemComponent],
	providers: [ToolbarHelperService, MentionService, UploadService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddItemModule {}
