import { DataEntity } from '@gamewaver/core';

export enum UserStatus {
	PENDING = 'pending',
	CONFIRM = 'confirm',
}

export enum UserGender {
	MALE = 'male',
	FEMALE = 'female',
	OTHER = 'other',
}

export enum UserRole {
	ADMIN = 'admin',
	USER = 'user',
}

export interface User extends DataEntity {
	username: string;
	email?: string;
	role: UserRole;
	status: UserStatus;

	avatar: string;
	summary: string;
	location: string;
	gender: UserGender;
}

export interface UpdateUserCmd {
	email: string;
	avatar: string;
	summary: string;
	location: string;
	gender: UserGender;
}

export interface UserViewModel extends User {
	joinedAt: string;
	userRole: string;
	daysRegistered?: string;
	url?: string;
	fromMention: boolean;
}

export type MenuUser = Pick<User, 'id' | 'username'> | undefined;
