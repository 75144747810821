import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { ToolbarButtonType } from './models';
import { ToolbarHelperService } from './toolbar-helper.service';
import '@github/markdown-toolbar-element';
import { FormattingHelpComponent } from './formatting-help.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs';
import { OnDestroyCleanup } from '@gamewaver/core';

@Component({
	selector: 'gw-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends OnDestroyCleanup {
	@HostBinding('class') className = 'toolbar';
	@Output() textFormatted: EventEmitter<string> = new EventEmitter();
	btnType = ToolbarButtonType;
	compactView: boolean;

	constructor(private toolbarHelperService: ToolbarHelperService, private dialog: MatDialog, private breakpointObserver: BreakpointObserver) {
		super();
		this.breakpointObserver
			.observe([Breakpoints.XSmall])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(result => {
				this.compactView = false;

				if (result.matches) {
					this.compactView = true;
				}
			});
	}

	onBtnClick(btn: ToolbarButtonType): void {
		let formatted: string | undefined;

		switch (btn) {
			case ToolbarButtonType.Underline:
				formatted = this.toolbarHelperService.newLineFormat('<u>underlined text</u>');
				break;

			case ToolbarButtonType.Video:
				formatted = this.toolbarHelperService.formatVideoLink();
				break;

			case ToolbarButtonType.Table:
				let table = `
| Syntax      | Description |
| ----------- | ----------- |
| Header      | Title       |
| Paragraph   | Text        |\n\n`;
				formatted = this.toolbarHelperService.newLineFormat(table);
				break;
		}

		this.textFormatted.emit(formatted);
	}

	onFormatHelp(): void {
		this.dialog.open(FormattingHelpComponent);
	}
}
