import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { isEqual, isNumber } from 'lodash-es';
import { PostsApiService } from './posts.api.service';
import { VotesApiService } from './votes.api.service';
import { EnvironmentService } from '../core/services/environment.service';
import { LoadingService } from '../core/services/loading.service';
import { SnackbarService } from '../core/services/snackbar.service';
import { AuthService } from '../auth/auth.service';
import { RouteTag, SidebarParams, TagService } from '@gamewaver/navigation';
import { ScrollPositionService } from '../core/services/scroll-position.service';
import { Dictionary, Sorting, SnackbarErrors, ItemsService } from '@gamewaver/core';
import { UserRole } from '@gamewaver/users';
import { postCategories, postVisibilities } from './models/post-consts';
import { PostViewModel, UserActionOnPost, VoteType, PostVisibility } from './models/post-view.model';
import { PostCmd, PostRoute, ProfilePostParams, GetVoteDto, GetPostDto, PostVoteCmd } from './models/post.models';

interface TimeFiler {
	startDate: string;
	endDate: string;
}

@Injectable({ providedIn: 'root' })
export class PostsService extends ItemsService<PostViewModel> {
	private _postRoute: PostRoute;
	private hasRefreshed: boolean;
	private sortTypes = {
		[SidebarParams.Fresh]: 'createdAt',
		[SidebarParams.Pupular]: 'upvotes',
		[SidebarParams.Commented]: 'comments',
	};

	action = UserActionOnPost.Posted;

	set postRoute(value: PostRoute) {
		if ((!isEqual(this._postRoute, value) || this.tagService.isProfileBackToHome) && !this.hasRefreshed) {
			this._postRoute = value;
			this.buildRequest();
			this.clear();
			this.getMany();
		}
		this.hasRefreshed = false;
	}

	set profilePostsParams(value: ProfilePostParams) {
		this.clear();
		this.buildProfilePostsRequest(value);
		this.getMany();
	}

	constructor(
		private postsApiService: PostsApiService,
		private loadingService: LoadingService,
		environmentService: EnvironmentService,
		private snackbarService: SnackbarService,
		private votesApiService: VotesApiService,
		private router: Router,
		private authService: AuthService,
		private scrollPosition: ScrollPositionService,
		private tagService: TagService,
	) {
		super(environmentService);
	}

	async getMany(): Promise<void> {
		if (this.noMoreItems) {
			return;
		}

		try {
			this.loadingService.setUILoading();
			const posts = (await this.postsApiService.findAll(this.requestParams)).result;
			const mappedPosts = posts.items.map(p => this.map(p));
			this.items = this.items.concat(mappedPosts);
			this.paging.skip = this.items.length;
			this.noMoreItems = this.items.length === posts.total;
			this.itemsSubject.next(this.items);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetPosts);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async getOne(id: number): Promise<void> {
		const foundPost = this.items.find(x => x.id === id);
		if (foundPost) {
			this.itemSubject.next(foundPost);

			return;
		}

		try {
			this.loadingService.setUILoading();
			const post = (await this.postsApiService.findOne(id)).result;
			this.itemSubject.next(this.map(post));
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetPost);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async create(cmd: PostCmd): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const post = (await this.postsApiService.create(cmd)).result;
			this.tryInsertPost(this.map(post));
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.CreatePost);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async edit(cmd: PostCmd, id: number): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const post = (await this.postsApiService.update(id, cmd)).result;
			const mappedPost = this.map(post);
			this.deleteOrReplace(id, mappedPost);
			this.itemSubject.next(mappedPost);
			this.snackbarService.showInfo('Post Edited Successfully');
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.EditPost);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async delete(id: number): Promise<void> {
		try {
			this.loadingService.setUILoading();
			await this.postsApiService.delete(id);
			this.deleteOrReplace(id);
			this.snackbarService.showInfo('Post Deleted Successfully');

			if (this.tagService.tag === RouteTag.PostPage) {
				this.router.navigateByUrl('/');
			}
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.DeletePost);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async vote(cmd: PostVoteCmd): Promise<void> {
		try {
			const vote = (await this.votesApiService.create(cmd)).result;
			this.updateVote(vote);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.CreateVote);
		}
	}

	async unVote(id: number): Promise<void> {
		try {
			const vote = (await this.votesApiService.delete(id)).result;
			this.updateVote(vote, true);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.DeleteVote);
		}
	}

	updateVote(vote: GetVoteDto, isDelete?: boolean): void {
		let post = this.items.find(x => x.id === vote.postId);

		// when we load directly from post page
		if (!post) {
			post = this.itemSubject.value!;
		}

		if (vote.type === VoteType.Upvote && !isDelete) {
			post.upvotes++;
		} else if (vote.type === VoteType.DownVote && !isDelete) {
			post.downvotes++;
		} else if (vote.type === VoteType.Upvote && isDelete) {
			post.upvotes--;
		} else if (vote.type === VoteType.DownVote && isDelete) {
			post.downvotes--;
		}

		post.vote = isDelete ? undefined : vote;
	}

	updateComment(postId: number, isDelete?: boolean): void {
		const post = this.items.find(x => x.id === postId);
		if (!post) {
			return;
		}

		if (isDelete) {
			post.comments--;
		} else {
			post.comments++;
		}
		const i = this.items.findIndex(x => x.id === postId);
		this.items.splice(i, 1, post);
		this.itemsSubject.next(this.items);
	}

	refresh(): void {
		this.hasRefreshed = true;
		this.requestParams = { paging: this.paging };
		this._postRoute = {}; // to avoid calling getMany from posts component
		this.scrollPosition.reset(); // to avoid scroll triggering onScrollDown in posts
		this.clear();
		this.getMany();
	}

	private map(post: GetPostDto): PostViewModel {
		return {
			...post,
			date: this.getPostDate(post),
			tooltipDate: moment(this.getPostDate(post)).format('MMMM DD, YYYY [at] hh:mm A'),
			userRole: post.role !== UserRole.USER ? post.role : '',
			categoryLabel: postCategories[post.category].label,
			userActionOnPost: this.action,
			vote: post.userVote
		};
	}

	private getPostDate(post: GetPostDto): string {
		let date = post.createdAt;

		if (this.action === UserActionOnPost.Commented && post.commentCreated) {
			date = post.commentCreated;
		} else if (this.action === UserActionOnPost.Voted && post.voteCreated) {
			date = post.voteCreated;
		}

		return date.toString();
	}

	private tryInsertPost(post: PostViewModel): void {
		if (post.visibility === PostVisibility.PUBLIC) {
			this.items.unshift(post);
			this.itemsSubject.next(this.items);
			this.snackbarService.showInfo('Post Added Successfully');
		} else {
			this.snackbarService.showInfo(`Posts with visibility ${postVisibilities[post.visibility].label} appear on profile page.`, 7000);
		}
	}

	private buildRequest(): void {
		const filters: Dictionary<any>[] = [];
		let sorting: Sorting | undefined;

		if (this._postRoute.searchQuery) {
			filters.push({ search: this._postRoute.searchQuery });
		}
		if (this._postRoute.category) {
			filters.push({ category: this._postRoute.category.split('-').join('_') });
		}
		if (this._postRoute.sortType) {
			sorting = { sortBy: this.sortTypes[this._postRoute.sortType] };

			if (this._postRoute.sortTime) {
				const { startDate, endDate } = this.getSortTime(this._postRoute.sortTime);
				filters.push({ startDate });
				filters.push({ endDate });
			}
		}

		if (this._postRoute.month || this._postRoute.year) {
			const input = this._postRoute.year ? +this._postRoute.year : this._postRoute.month;
			const { startDate, endDate } = this.getTime(input!);
			filters.push({ startDate });
			filters.push({ endDate });
		}

		this.requestParams = { paging: this.paging, sorting: sorting, filters };
	}

	private buildProfilePostsRequest({ tag, userId }: ProfilePostParams): void {
		const filters: Dictionary<any>[] = [];
		let sorting: Sorting | undefined;

		switch (tag) {
			case RouteTag.ProfilePosts:
				filters.push({ author: userId });
				sorting = { sortBy: 'createdAt' };
				this.action = UserActionOnPost.Posted;
				break;
			case RouteTag.ProfileComments:
				filters.push({ commentAuthor: userId });
				sorting = { sortBy: 'commentCreated' };
				this.action = UserActionOnPost.Commented;
				break;
			case RouteTag.ProfileVotes:
				filters.push({ voteAuthor: userId });
				sorting = { sortBy: 'voteCreated' };
				this.action = UserActionOnPost.Voted;
				break;
		}

		if (this.authService.isOwn(userId)) {
			filters.push({ visibility: Object.values(PostVisibility).join(',') });
		}

		this.requestParams = { paging: this.paging, sorting, filters };
	}

	private getSortTime(param: string): TimeFiler {
		const sortTimes = {
			[SidebarParams.Daily]: 'days',
			[SidebarParams.Weekly]: 'weeks',
			[SidebarParams.Monthly]: 'month',
		};

		return { startDate: moment().subtract(1, sortTimes[param]).format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') };
	}

	private getTime(input: string | number): TimeFiler {
		if (isNumber(input)) {
			return {
				startDate: moment(moment().year(input)).startOf('year').format('YYYY-MM-DD'),
				endDate: moment(moment().year(input)).endOf('year').format('YYYY-MM-DD'),
			};
		}

		return {
			startDate: moment(moment().month(input)).startOf('month').format('YYYY-MM-DD'),
			endDate: moment(moment().month(input)).endOf('month').format('YYYY-MM-DD'),
		};
	}
}
