import { Options } from '@gamewaver/shared';
import { PostCategory, PostVisibility } from './post-view.model';

export const postVisibilities: Record<PostVisibility, Options> = {
	[PostVisibility.PUBLIC]: { label: 'Public', icon: 'public', description: 'Visible for everyone' },
	[PostVisibility.UNLISTED]: { label: 'Unlisted', icon: 'link', description: 'Visible with direct link' },
	[PostVisibility.PRIVATE]: { label: 'Private', icon: 'lock', description: 'Visible only for its author' },
};

export const postCategories: Record<PostCategory, Options> = {
	[PostCategory.IMAGE]: { label: 'Image' },
	[PostCategory.VIDEO]: { label: 'Video' },
	[PostCategory.BLOG_POST]: { label: 'Blog Post' },
	[PostCategory.MEME]: { label: 'Meme' },
	[PostCategory.NSFW]: { label: 'NSFW' },
	[PostCategory.OTHER]: { label: 'Other' },
};
