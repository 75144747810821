import { Injectable, InjectionToken, Injector } from '@angular/core';
import { PortalPosition, PortalService } from '@gamewaver/core';
import { MentionDom } from '@gamewaver/shared';
import { UsersService } from '@gamewaver/users';
import { UserCardComponent } from './user-card.component';

export const CARD_TOKEN = new InjectionToken<UserCardService>('portal-data');

@Injectable()
export class UserCardService {
	constructor(private usersService: UsersService, private portalService: PortalService<UserCardComponent>) {}

	open(data: MentionDom): void {
		this.usersService.getOne(data.id, false);
		this.portalService.open({
			component: UserCardComponent,
			origin: data.element,
			position: PortalPosition.Right,
			offsetX: 10,
			disableScroll: true,
			injector: Injector.create({
				providers: [{ provide: CARD_TOKEN, useValue: this }],
			}),
		});
	}

	close(): void {
		this.portalService.close();
	}
}
