import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TimeAgoPipe } from './time-ago.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { UserInfoComponent } from './user-info.component';
import { SelectorComponent } from './selector.component';
import { MentionDirective } from './mention.directive';

@NgModule({
	declarations: [TimeAgoPipe, UserInfoComponent, SelectorComponent, MentionDirective],
	imports: [CommonModule, MaterialModule, RouterModule, ReactiveFormsModule, InfiniteScrollModule, MarkdownModule.forChild(), FontAwesomeModule],
	exports: [
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		InfiniteScrollModule,
		FontAwesomeModule,
		MarkdownModule,
		RouterModule,

		// Pipes
		TimeAgoPipe,

		// Directives
		MentionDirective,

		// Components
		UserInfoComponent,
		SelectorComponent,
	],
})
export class SharedModule {}
