import { Injectable } from '@angular/core';
import { EnvironmentService, HttpClientService } from '@gamewaver/core';
import { ImgurReponse, ImgurSuccess, ImgurError } from './imgur-response';

@Injectable()
export class UploadService {
	BASE_URL = this.environmentService.uploadApiUrl;

	constructor(private httpClient: HttpClientService, private environmentService: EnvironmentService) {}

	upload(data: FormData): Promise<ImgurReponse<ImgurSuccess | ImgurError>> {
		return this.httpClient.post<ImgurReponse<ImgurSuccess | ImgurError>>(`${this.BASE_URL}`, data, { absoluteUrl: true });
	}
}
