import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class ScrollPositionService {
	private postsScrolledPos?: [number, number];

	constructor(private viewportScroller: ViewportScroller) {}

	savePostsScrollPos(): void {
		this.postsScrolledPos = this.viewportScroller.getScrollPosition();
		this.scrollToTop();
	}

	restorePostsScrollPos(): void {
		if (this.postsScrolledPos) {
			this.viewportScroller.scrollToPosition(this.postsScrolledPos);
		}
	}

	reset(): void {
		this.postsScrolledPos = undefined;
	}

	private scrollToTop(): void {
		this.viewportScroller.scrollToPosition([0, 0]);
	}
}
