<ng-container *ngIf="user$ | async as user">
	<div class="avatar">
		<img [src]="user.avatar" alt="{{ user.username }}' avatar" />
	</div>

	<div class="info">
		<div class="main">
			<span class="name">{{ user.username }}</span>
			<span class="role" [ngClass]="user.role" *ngIf="user.userRole">{{ user.userRole | titlecase }}</span>
		</div>
		<div class="additional">
			<span class="location">{{ user.location }}</span>
			<span class="days">{{ user.daysRegistered }}</span>
		</div>
	</div>

	<div class="summary">{{ user.summary }}</div>

	<div class="buttons">
		<a class="card-button" mat-raised-button color="primary" [href]="user.url" (click)="onProfile()">View Profile</a>
	</div>
</ng-container>
