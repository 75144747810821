import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlHelperService } from './url-helper.service';

@Injectable()
export class RedirectHelperService {
	constructor(private router: Router, private urlHelper: UrlHelperService) {}

	goToHome(): void {
		this.router.navigateByUrl('/');
	}

	goToLogin(): void {
		this.router.navigateByUrl(this.urlHelper.login);
	}

	goToProfile(id: number): void {
		this.router.navigateByUrl(this.urlHelper.profile(id));
	}

	goToCategory(category: string): void {
		this.router.navigateByUrl(this.urlHelper.category(category));
	}

	goToPage(url: string): void {
		this.router.navigateByUrl(url);
	}
}
