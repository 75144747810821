import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@gamewaver/shared';
import { CommentComponent } from './comment.component';
import { CommentsService } from './comments.service';

@NgModule({
	declarations: [CommentComponent],
	imports: [CommonModule, SharedModule],
	exports: [CommentComponent],
	providers: [CommentsService],
})
export class CommentsModule {}
