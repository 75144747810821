export enum AuthParams {
	Auth = 'auth',
	Login = 'login',
	Register = 'register',
	Forgotten = 'forgotten-password'
}

export enum AboutParams {
	About = 'about',
	Faq = 'faq',
	Team = 'team',
	Tos = 'tos',
}

export enum UsersParams {
	Users = 'users',
	Profile = 'profile',
	Edit = 'edit',
}

export enum PostParams {
	Post = 'post',
	Edit = 'edit',
}

export enum SidebarParams {
	Fresh = 'fresh',
	Pupular = 'popular',
	Commented = 'most-commented',

	Daily = 'daily',
	Weekly = 'weekly',
	Monthly = 'monthly',

	Category = 'category',
	Search = 'search',
	Post = 'post'
}
