import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewportScroller } from '@angular/common';
import { RouterEventsService } from '@gamewaver/navigation';
import { AuthApiService, AuthService } from '@gamewaver/auth';

@Component({
	selector: 'gw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild('sidenav') sidenav: MatSidenav;
	cssClass?: string;

	constructor(
		private authApiService: AuthApiService,
		private viewportScroller: ViewportScroller,
		private authService: AuthService,
		private routerEvents: RouterEventsService,
	) {
		this.routerEvents.scrolled$.subscribe(e => {
			if (e.position) {
				// backward navigation
				this.viewportScroller.scrollToPosition(e.position);
			} else if (e.anchor) {
				// anchor navigation
				this.viewportScroller.scrollToAnchor(e.anchor);
			} else {
				// forward navigation
				this.viewportScroller.scrollToPosition([0, 0]);
			}
		});

		this.routerEvents.navigationEnd$.subscribe(e => {
			if (e.snapshot.parent?.parent?.routeConfig?.path) {
				this.cssClass = e.snapshot.parent.parent.routeConfig.path;
			} else {
				this.cssClass = e.snapshot.parent?.routeConfig?.path || 'home';
			}
		});
	}

	ngOnInit(): void {
		if (this.authApiService.isLoggedIn()) {
			this.authService.getProfile();
		}
	}

	close(): void {
		this.sidenav.close();
	}
}
