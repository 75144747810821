import { Injectable } from '@angular/core';
import { PagedData, RequestParams, IResponse, HttpClientService } from '@gamewaver/core';
import { CommentCmd, GetCommentDto, GetCommentsCountDto } from './comment.models';

@Injectable({
	providedIn: 'root'
})
export class CommentsApiService {
	BASE_URL = `comments`;

	constructor(private httpClient: HttpClientService) {}

	create(cmd: CommentCmd, postId: number): Promise<IResponse<GetCommentDto>> {
		return this.httpClient.post<IResponse<GetCommentDto>>(`${this.BASE_URL}/${postId}`, cmd);
	}

	findAll(params?: RequestParams): Promise<IResponse<PagedData<GetCommentDto>>> {
		return this.httpClient.get<IResponse<PagedData<GetCommentDto>>>(`${this.BASE_URL}${this.httpClient.getParams(params)}`);
	}

	findCountByPostIds(ids: number[]): Promise<IResponse<GetCommentsCountDto[]>> {
		return this.httpClient.get<IResponse<GetCommentsCountDto[]>>(`${this.BASE_URL}/count?ids=${ids.join(',')}`);
	}

	delete(id: number): Promise<IResponse<GetCommentDto>> {
		return this.httpClient.delete<IResponse<GetCommentDto>>(`${this.BASE_URL}/${id}`);
	}

	update(id: number, cmd: CommentCmd): Promise<IResponse<GetCommentDto>> {
		return this.httpClient.put<IResponse<GetCommentDto>>(`${this.BASE_URL}/${id}`, cmd);
	}
}
