import { Component, Input, OnChanges } from '@angular/core';
import { TypedChange } from '@gamewaver/core';
import * as moment from 'moment';
import { Notification, NotificationStatus, NotificationType } from './notification';
import { NotificationDialogService } from './notification-dialog.service';
import { NotificationsService } from './notifications.service';

@Component({
	selector: 'gw-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnChanges {
	@Input() notification: Notification;
	date: string;
	content: string;

	get isUnread(): boolean {
		return this.notification.status === NotificationStatus.Unread;
	}

	constructor(private notificationDialogService: NotificationDialogService, private notificationsService: NotificationsService) {}

	ngOnChanges(changes: TypedChange<NotificationComponent>): void {
		if (changes.notification?.currentValue) {
			this.date = moment(this.notification.createdAt).format('D  MMM YY');
			this.content = this.getContent(this.notification);
		}
	}

	getContent(notification: Notification): string {
		switch (notification.type) {
			case NotificationType.PostMention:
			case NotificationType.CommentMention:
				return ` mentioned you: ${notification.content}`;

			default:
				return 'You have a notification';
		}
	}

	onNotification(): void {
		this.notificationDialogService.toggle();

		if (this.isUnread) {
			this.notificationsService.edit(NotificationStatus.Read, this.notification.id);
		}
	}

	onMarkRead(event: PointerEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.notificationsService.edit(NotificationStatus.Read, this.notification.id);
	}

	onDelete(event: PointerEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.notificationsService.delete(this.notification.id);
	}
}
