import { SnackbarService } from '@gamewaver/core/services/snackbar.service';
import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { isEqual } from 'lodash-es';
import { UrlHelperService } from '@gamewaver/navigation';
import { TypedChange } from '@gamewaver/core';

export interface UserInfo {
	id: number;
	avatar?: string;
	username?: string;
	role?: string;
	joinedAt?: string;
}

export enum UserInfoContext {
	Profile = 'profile',
	ProfileEdit = 'profile-edit',
	ProfilePost = 'profile-post',
	AddItem = 'add-item',
	Post = 'post',
}

@Component({
	selector: 'gw-user-info',
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnChanges {
	@Input() userInfo: UserInfo;
	@Input() showAvatarFallback = true;
	@Input() contextInput: UserInfoContext;
	private defaultAvatar = window.location.origin + '/assets/images/common/no_avatar.jpg';
	avatar?: string;
	context = UserInfoContext;
	url: string;

	@HostBinding('class') className = 'user-info';
	@HostBinding(`class.profile`) get profile(): boolean {
		return this.contextInput === this.context.Profile;
	}

	@HostBinding(`class.profile-edit`) get profileEdit(): boolean {
		return this.contextInput === this.context.ProfileEdit;
	}

	constructor(private snackbarService: SnackbarService, private urlHelper: UrlHelperService) {}

	get shouldShowAvatarImage(): boolean {
		switch (this.contextInput) {
			case this.context.AddItem:
			case this.context.ProfileEdit:
			case this.context.Profile:
				return true;
		}

		return false;
	}

	ngOnChanges(changes: TypedChange<UserInfoComponent>): void {
		if (!isEqual(changes.userInfo?.currentValue, changes.userInfo?.previousValue)) {
			this.avatar = this.userInfo.avatar;
			this.url = this.urlHelper.profile(this.userInfo.id);

			if (!this.userInfo.avatar && this.showAvatarFallback) {
				this.avatar = this.defaultAvatar;
			}
		}
	}

	onError(): void {
		this.avatar = this.defaultAvatar;

		if (this.contextInput === UserInfoContext.ProfileEdit) {
			this.snackbarService.showError('Error loading image, default applied.');
		}
	}
}
