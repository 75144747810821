<mat-card class="post">
	<gw-user-info [userInfo]="userInfo" [contextInput]="userInfoContext">
		<div class="info">
			<mat-icon [matTooltip]="visibilities[post.visibility].description">{{ visibilities[post.visibility].icon }}</mat-icon>
			<a (click)="onCategory()" *ngIf="!isProfilePage; else categoryText">{{ post.categoryLabel }}</a>
			<span class="spacer">·</span>
			<span [matTooltip]="post.tooltipDate">{{ post.date | timeAgo }}</span>
			<span class="spacer" *ngIf="isProfilePage">· {{ post.userActionOnPost }}</span>
		</div>
	</gw-user-info>

	<ng-template #categoryText>
		<span>{{ post.categoryLabel }}</span>
	</ng-template>

	<mat-card-content>
		<markdown [data]="post.content" gwMention (mentionClick)="onMention($event)" ngPreserveWhitespaces></markdown>
	</mat-card-content>

	<mat-card-actions>
		<span class="left">
			<button mat-button (click)="onVote(voteType.Upvote)">
				<mat-icon [matBadge]="post.upvotes" matBadgeColor="primary" color="{{ post.vote?.type === voteType.Upvote ? 'primary' : '' }}"
					>arrow_upward</mat-icon
				>
			</button>

			<button mat-button (click)="onVote(voteType.DownVote)">
				<mat-icon [matBadge]="post.downvotes" matBadgeColor="primary" color="{{ post.vote?.type === voteType.DownVote ? 'primary' : '' }}"
					>arrow_downward</mat-icon
				>
			</button>
		</span>

		<span class="right">
			<a
				mat-button
				[routerLink]="[url]"
				*ngIf="showComment"
				(click)="onPostLink()"
			>
				<mat-icon [matBadge]="post.comments" matBadgeColor="primary">comment</mat-icon>
			</a>

			<button mat-button [matMenuTriggerFor]="menuActions">
				<mat-icon>more_horiz</mat-icon>
			</button>

			<mat-menu #menuActions="matMenu">
				<button mat-menu-item (click)="onCopyLink()">
					<mat-icon>content_copy</mat-icon>
					<span class="menu-item-name">Copy link</span>
				</button>

				<button mat-menu-item (click)="onEdit()" *ngIf="showEdit">
					<mat-icon>edit</mat-icon>
					<span class="menu-item-name">Edit</span>
				</button>

				<div [matTooltip]="deleteDisabled ? 'Delete is not allowed for posts with comments.' : ''">
					<button mat-menu-item (click)="onDelete()" *ngIf="canEditOrDelete" [disabled]="deleteDisabled">
						<mat-icon>delete</mat-icon>
						<span class="menu-item-name">Delete</span>
					</button>
				</div>
			</mat-menu>
		</span>
	</mat-card-actions>
</mat-card>
