import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@gamewaver/shared';
import { NotificationsService } from './notifications.service';
import { NotificationDialogService } from './notification-dialog.service';
import { NotificationsComponent } from './notifications.component';
import { NotificationComponent } from './notification.component';
import { RouterModule } from '@angular/router';
import { NotificationsApiService } from './notifications.api.service';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
	SharedModule,
	RouterModule
  ],
  providers: [NotificationsService, NotificationDialogService, NotificationsApiService]
})
export class NotificationsModule { }
