<mat-dialog-content class="formatting-help">
  <h1>TEXT FORMATTING</h1>
  <p>
    We support markdown and these examples shows you how to add formatting to
    the text of your comments and posts, similar to HTML.
  </p>

  <mat-table [dataSource]="dataSource" class="formatting-table">
    <!-- Position Column -->
    <ng-container matColumnDef="syntax">
      <mat-header-cell *matHeaderCellDef>Syntax</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.syntax }} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="example">
      <mat-header-cell *matHeaderCellDef>Example</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <markdown [data]="element.example" ngPreserveWhitespaces></markdown>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="button-wrapper">
    <a
      href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
      mat-stroked-button
      target="_blank"
      color="primary"
      >Markdown Cheatsheet</a
    >
  </div>
</mat-dialog-content>



