import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnDestroyCleanup } from '@gamewaver/core';
import * as moment from 'moment';
import { SidebarItem } from './sidebar-view.models';

@Component({
	selector: 'gw-archive',
	templateUrl: './archive.component.html',
	styleUrls: ['./archive.component.scss', './sidebar.component.scss'],
})
export class ArchiveComponent extends OnDestroyCleanup {
	@Input() months: SidebarItem[];
	@Input() years: SidebarItem[];
	@Output() selected: EventEmitter<SidebarItem> = new EventEmitter();
	currentYear = moment().format('YYYY');

	onClick(item: SidebarItem): void {
		this.selected.emit(item);
	}
}
