import { ElementRef, Injectable, InjectionToken, Injector } from '@angular/core';
import { PortalService } from '@gamewaver/core';
import { UsersService } from '@gamewaver/users';
import { Observable, Subject } from 'rxjs';
import { MentionComponent } from './mention.component';
import { Mention } from './models';

export const MENTION_TOKEN = new InjectionToken<MentionService>('portal-data');

@Injectable()
export class MentionService {
	private _mentionOutput = new Subject<Mention>();
	private _mentions = new Subject<Mention[]>();
	private replaced: boolean;
	private origin: ElementRef<HTMLTextAreaElement>;

	get mentionOutput$(): Observable<Mention> {
		return this._mentionOutput.asObservable();
	}

	set mentionOutput(value: Mention) {
		this._mentionOutput.next(value);
	}

	get mentions$(): Observable<Mention[]> {
		return this._mentions.asObservable();
	}

	constructor(private usersService: UsersService, private portalService: PortalService<MentionComponent>) {}

	setOrigin(origin: ElementRef<HTMLTextAreaElement>): void {
		this.origin = origin;
	}

	close(): void {
		this.portalService.close();
	}

	setMentionInput(input: string, carretPos: number = 0): void {
		if (this.replaced) {
			this.replaced = !this.replaced;

			return;
		}

		const lastAtSign = input?.substring(0, carretPos + 1).lastIndexOf('@');
		const mInput = input?.substring(lastAtSign! + 1, carretPos + 1);
		if (mInput && mInput?.length >= 3 && lastAtSign > -1) {
			this.getMentions(mInput);
		}
	}

	setSelectedMention(input: string, carretPos: number, mention: Mention): string {
		const lastAtSign = input?.substring(0, carretPos + 1).lastIndexOf('@');
		const textMention = `[@${mention.name}](<@${mention.id}>)`;
		const mInput = input?.substring(lastAtSign, carretPos + 1);
		const splitted  = input.split('');
		splitted.splice(lastAtSign, mInput.length, textMention);
		this.replaced = true;

		return splitted.join('');
	}

	private async getMentions(searchTerm: string): Promise<any> {
		const mentions = await this.usersService.findMentions(searchTerm);
		if (mentions.length) {
			this.portalService.open({
				component: MentionComponent,
				origin: this.origin,
				disableScroll: true,
				offsetY: 44,
				injector: Injector.create({
					providers: [{ provide: MENTION_TOKEN, useValue: this }],
				})
			});

			this._mentions.next(mentions);
		}
	}
}
