import { Injectable } from '@angular/core';
import { postCategories } from '@gamewaver/posts';
import { UrlHelperService } from '@gamewaver/navigation';
import * as moment from 'moment';
import { SidebarItem, SidebarType, SortTime, SortType } from './sidebar-view.models';

@Injectable()
export class SidebarBuilderService {
	constructor(private urlHelper: UrlHelperService) {}

	build(): SidebarItem[] {
		const model: SidebarItem[] = [
			{
				label: 'Fresh',
				type: SidebarType.Sort,
				subType: SortType.Fresh,
				icon: 'schedule',
				url: 'fresh',
				active: true,
			},
			{
				label: 'Popular',
				type: SidebarType.Sort,
				subType: SortType.Popular,
				icon: 'schedule',
				url: 'popular',
			},
			{
				label: 'Commented',
				type: SidebarType.Sort,
				subType: SortType.Commented,
				icon: 'schedule',
				url: 'most-commented',
			},
			{
				label: 'All',
				type: SidebarType.Time,
				subType: SortTime.All,
				url: '',
				active: true,
			},
			{
				label: 'Daily',
				type: SidebarType.Time,
				subType: SortTime.Days1,
				url: 'daily',
			},
			{
				label: 'Weekly',
				type: SidebarType.Time,
				subType: SortTime.Days7,
				url: 'weekly',
			},
			{
				label: 'Monthly',
				type: SidebarType.Time,
				subType: SortTime.Days30,
				url: 'monthly',
			},
			...this.getCategories(),
			...this.getMonthsFromCurrentYear(),
			...this.getYearsFromStart(),
		];

		return model.map((x, i) => ({ ...x, id: `${x.label.toLowerCase()}-${i++}` }));
	}

	private getCategories(): SidebarItem[] {
		return Object.values(postCategories).map(({ label }) => ({
			label: label,
			type: SidebarType.Category,
			url: this.urlHelper.category(label),
		}));
	}

	private getYearsFromStart(): SidebarItem[] {
		const yearsFromStart = moment().diff('2020-01-01', 'years') + 1;
		const years: string[] = [];
		for (let i = 0; i < yearsFromStart; i++) {
			years.push(`${2020 + i}`);
		}

		return years.map(x => ({ label: x, type: SidebarType.Year, url: this.urlHelper.archive(x, true) }));
	}

	private getMonthsFromCurrentYear(): SidebarItem[] {
		const monthsFromYear = moment().diff(`${moment().year()}-01-01`, 'months') + 1;
		const months: string[] = [];
		for (let i = 1; i <= monthsFromYear; i++) {
			const temp = i <= 9 ? `0` : '';
			const month = moment(`${moment().year()}-${temp}${i}-01`).format('MMM');
			months.push(month);
		}

		return months.map(x => ({ label: x, type: SidebarType.Month, url: this.urlHelper.archive(x) }));
	}
}
