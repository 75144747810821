import { PostCategory, PostVisibility } from '@gamewaver/posts';

export interface AddItem {
	isPost?: boolean;
	minLength: number;
	maxLength: number;
	content?: string;
	category?: PostCategory;
	id?: number;
	userAvatar?: string;
	userId?: number;
	visibility?: PostVisibility;
}

export enum TabOption {
	Write = 'Write',
	Preview = 'Preview',
}

export enum ToolbarButtonType {
	Header = 'title',
	Bold = 'format_bold',
	Italic = 'format_italic',
	Underline = 'format_underlined',
	Quote = 'format_quote',
	InsertLink = 'add_link',
	List = 'format_list_bulleted',
	Image = 'image',
	Video = 'smart_display',

	Strikethrough = 'strikethrough_s',
	Code = 'code',
	OrderedList = 'format_list_numbered',
	Table = 'table_view',
	Checkbox = 'check_box',
	More = 'expand_more',
	TextFormat = 'text_format',
}

export interface Mention {
	id: number,
	name: string,
	image: string,
}
