import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EnvironmentService, ItemsService, LoadingService, SnackbarErrors, SnackbarService } from '@gamewaver/core';
import { UserRole } from '@gamewaver/users';
import { CommentsApiService } from './comments.api.service';
import { CommentCmd, CommentViewModel, GetCommentDto } from './comment.models';
import { PostsService } from '@gamewaver/posts';

@Injectable()
export class CommentsService extends ItemsService<CommentViewModel> {
	private indexOfEditedComment: number;
	private editedComment?: CommentViewModel;
	postId?: number;

	constructor(
		private commentsApiService: CommentsApiService,
		private loadingService: LoadingService,
		environmentService: EnvironmentService,
		private snackbarService: SnackbarService,
		private postsService: PostsService
	) {
		super(environmentService);
	}

	async getMany(): Promise<void> {
		if (this.noMoreItems) {
			return;
		}

		try {
			this.loadingService.setUILoading();
			const commentsRequest = { paging: this.paging, filters: [{ postId: this.postId }] };
			const comments = (await this.commentsApiService.findAll(commentsRequest)).result;
			const mapped = comments.items.map(c => this.map(c));

			this.items = this.items.concat(mapped);
			this.paging.skip = this.items.length;
			this.noMoreItems = this.items.length === comments.total;
			this.itemsSubject.next(this.items);
		} catch ({ error }) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetComments);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async create(cmd: CommentCmd): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const comment = (await this.commentsApiService.create(cmd, this.postId!)).result;
			this.items.unshift(this.map(comment));
			this.itemsSubject.next(this.items);
			this.postsService.updateComment(this.postId!);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.CreateComment);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async edit(cmd: CommentCmd, id: number): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const comment = (await this.commentsApiService.update(id, cmd)).result;
			this.deleteOrReplace(comment.id, this.map(comment), this.indexOfEditedComment);
			this.snackbarService.showInfo('Comment Edited Successfully');
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.EditComment);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async delete(id: number): Promise<void> {
		try {
			this.loadingService.setUILoading();
			await this.commentsApiService.delete(id);
			this.deleteOrReplace(id);
			this.snackbarService.showInfo('Comment Deleted Successfully');
			this.postsService.updateComment(this.postId!, true);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.DeleteComment);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	startEdit(id: number): CommentViewModel {
		this.indexOfEditedComment = this.items.findIndex(x => x.id === id);
		this.editedComment = this.items[this.indexOfEditedComment];
		this.items.splice(this.indexOfEditedComment, 1);
		this.itemsSubject.next(this.items);

		return this.editedComment;
	}

	cancelEdit(): void {
		if (!this.editedComment) {
			return;
		}

		this.items.splice(this.indexOfEditedComment, 0, this.editedComment);
		this.itemsSubject.next(this.items);
		this.editedComment = undefined;
	}

	clearComments(): void {
		this.clear();
		this.postId = undefined;
	}

	private map(comment: GetCommentDto): CommentViewModel {
		return {
			id: comment.id,
			content: comment.content,
			authorId: comment.authorId,
			postId: comment.postId,
			avatar: comment.avatar,
			username: comment.username,
			date: comment.createdAt.toString(),
			tooltipDate: moment(comment.createdAt).format('MMMM DD, YYYY [at] hh:mm A'),
			userRole: comment.role === UserRole.USER ? '' : comment.role,
		};
	}
}
