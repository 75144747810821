import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card.component';
import { UserCardService } from './user-card.service';
import { MaterialModule } from '@gamewaver/shared/material-module';

@NgModule({
  declarations: [UserCardComponent],
  imports: [
    CommonModule,
	MaterialModule,
  ],
  providers: [UserCardService],
})
export class UserCardModule { }
