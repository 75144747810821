import { DataEntity } from '@gamewaver/core';
import { User } from '@gamewaver/users';

type NotificationUser = Pick<User, 'id' | 'username' | 'avatar'>;

export enum NotificationType {
	PostMention = 'post_mention',
	CommentMention = 'comment_mention',
	PostReply = 'post_reply',
	PostUpvote = 'post_upvote',
	PostDownvote = 'post_downvote',
	PrivateMessage = 'private_message',
}

export enum NotificationStatus {
	Read = 'read',
	Unread = 'unread',
}

export interface Notification extends DataEntity {
	type: NotificationType;
	url?: string;
	status: NotificationStatus;
	content?: string;
	initiator: NotificationUser;
	recipient: NotificationUser;
}

export interface NotificationCount {
	count: number;
}
