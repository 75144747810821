import { Injectable } from '@angular/core';
import { ActivationEnd, ActivationStart, GuardsCheckEnd, NavigationEnd, Router, Scroll } from '@angular/router';
import { buffer, distinctUntilChanged, filter, map, Observable, Subject } from 'rxjs';

@Injectable()
export class RouterEventsService {
	private _navigationStart = new Subject<ActivationStart>();
	private _navigationEnd = new Subject<ActivationEnd>();
	private _scroll = new Subject<Scroll>();

	get navigationStart$(): Observable<ActivationStart> {
		return this._navigationStart.asObservable();
	}

	get navigationEnd$(): Observable<ActivationEnd> {
		return this._navigationEnd.asObservable();
	}

	get scrolled$(): Observable<Scroll> {
		return this._scroll.asObservable();
	}

	constructor(private router: Router) {
		this.navigationStart();
		this.navigationEnd();
		this.scroll();
	}

	private navigationStart(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof ActivationStart),
				buffer(this.router.events.pipe(filter(e => e instanceof GuardsCheckEnd))),
				map(events => events[events.length - 1] as ActivationStart),
				distinctUntilChanged()
			)
			.subscribe(this._navigationStart);
	}

	private navigationEnd(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof ActivationEnd),
				buffer(this.router.events.pipe(filter(e => e instanceof NavigationEnd))),
				map(events => events[0] as ActivationEnd),
				distinctUntilChanged()
			)
			.subscribe(this._navigationEnd);
	}

	private scroll(): void {
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(this._scroll);
	}
}
