export enum RouteTag {
	Profile = 'Profile',
	ProfileEdit = 'ProfileEdit',
	ProfilePosts = 'ProfilePosts',
	ProfileComments = 'ProfileComments',
	ProfileVotes = 'ProfileVotes',
	Register = 'Register',
	PostPage = 'PostPage',
	Home = 'Home',
}
