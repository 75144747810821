<gw-post [post]="post" [user]="user" *ngIf="!isEditPost && post" (editPost)="onEditPost()"></gw-post>

<gw-add-item
	(cancelEditItem)="onCancelEdit()"
	*ngIf="isEditPost || isEditComment || (!isEditPost && !isEditComment && user && post)"
	[addItem]="editItem"
></gw-add-item>

<div infiniteScroll (scrolled)="onScrollDown()" [infiniteScrollDistance]="1" *ngIf="comments$ | async as comments">
	<ng-container *ngFor="let item of comments">
		<gw-comment *ngIf="post" [comment]="item" [user]="user" (editComment)="onEditComment($event)" [postAuthorId]="post.authorId"></gw-comment>
	</ng-container>
</div>
