import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';
import { TabOption } from './models';

@Component({
	selector: 'gw-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	animations: [trigger('animateInkBar', [state('true', style({ left: '0px' })), state('false', style({ left: '100px' }))])],
})
export class TabsComponent {
	@Output() selectedTab: EventEmitter<TabOption> = new EventEmitter();

	activeTab: TabOption;
	tabOption = TabOption;

	onSelectedTab(tab: TabOption): void {
		this.activeTab = tab;
		this.selectedTab.emit(tab);
	}
}
