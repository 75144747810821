<mat-card class="comment">
  <mat-card-header>
    <div mat-card-avatar class="post-header-image">
      <a [routerLink]="[userProfileRoute]">
        <img [src]="comment.avatar  || './assets/images/common/no_avatar.jpg'" alt="{{ comment.username }}'s avatar">
      </a>
    </div>
    <mat-card-title>
      <a [routerLink]="[userProfileRoute]">
        {{ comment.username }}
      </a>
      <span class="role" [ngClass]="comment.userRole" *ngIf="comment.userRole">{{ comment.userRole | titlecase }}</span>
      <span class="role op" *ngIf="comment.authorId === postAuthorId">OP</span>
      <span class="time" [matTooltip]="comment.tooltipDate">· {{ comment.date | timeAgo }}</span>
    </mat-card-title>

    <mat-card-subtitle>
      <markdown [data]="comment.content" gwMention (mentionClick)="onMention($event)" ngPreserveWhitespaces></markdown>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-actions align="end">
    <button mat-button [matMenuTriggerFor]="menuActions">
      <mat-icon *ngIf="canEditOrDelete">more_horiz</mat-icon>
    </button>

    <mat-menu #menuActions="matMenu">
      <button mat-menu-item (click)="onEdit()" *ngIf="canEditOrDelete" >
        <mat-icon>edit</mat-icon>
        <span class="menu-item-name">Edit</span>
      </button>

      <button mat-menu-item (click)="onDelete()" *ngIf="canEditOrDelete">
        <mat-icon>delete</mat-icon>
        <span class="menu-item-name">Delete</span>
      </button>
    </mat-menu>
  </mat-card-actions>
</mat-card>

