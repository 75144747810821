import { NgModule } from '@angular/core';
import { RedirectHelperService } from './redirect-helper.service';
import { RouterEventsService } from './router-events.service';
import { UrlHelperService } from './url-helper.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [RedirectHelperService, RouterEventsService, UrlHelperService],
})
export class NavigationModule {}
