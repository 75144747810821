<a class="notification" [class.unread]="isUnread" [routerLink]="notification.url" (click)="onNotification()">
	<div class="image">
		<img [src]="notification.initiator.avatar" />
	</div>
	<div class="text">
		<span class="initiator">{{ notification.initiator.username }}</span>
		<span class="content">{{ content }}</span>
		<span class="date"> {{ date }}</span>
	</div>
	<div class="actions">
		<button mat-icon-button (click)="onMarkRead($event)" *ngIf="isUnread" matTooltip="Mark Read" matTooltipShowDelay="1000">
			<mat-icon>done_outline</mat-icon>
		</button>
		<button mat-icon-button (click)="onDelete($event)" matTooltip="Delete" matTooltipShowDelay="1000">
			<mat-icon>delete</mat-icon>
		</button>
	</div>
</a>
