import { Injectable } from '@angular/core';
import { PostRoute } from '@gamewaver/posts';
import { RedirectHelperService, UrlHelperService } from '@gamewaver/navigation';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SidebarBuilderService } from './sidebar-builder.service';
import { SidebarItem, SidebarType } from './sidebar-view.models';

@Injectable()
export class SidebarStateService {
	private items: SidebarItem[] = this.sidebarBuilder.build();
	private itemsSubject = new BehaviorSubject<SidebarItem[]>(this.items);
	private searchTermSubject = new Subject<string>();

	get items$(): Observable<SidebarItem[]> {
		return this.itemsSubject.asObservable();
	}

	get searchTerm$(): Observable<string> {
		return this.searchTermSubject.asObservable();
	}

	set postRoute(value: PostRoute) {
		this.setActiveFromRoute(value);
	}

	constructor(private sidebarBuilder: SidebarBuilderService, private redirectHelper: RedirectHelperService, private urlHelper: UrlHelperService) {}

	setActive(item: SidebarItem): void {
		this.deselect(item.type);
		const current = this.items.find(x => x.id === item.id)!;
		current.active = true;

		if (item.type === SidebarType.Time || item.type === SidebarType.Sort) {
			this.updateCateogires();
			const url = this.urlHelper.url(this.getSortSegments());
			this.redirectHelper.goToPage(url);
		}

		this.itemsSubject.next(this.items);
	}

	private setActiveFromRoute(route: PostRoute): void {
		const { sortType, sortTime, category, month, year, searchQuery } = route;

		if (isEmpty(route)) {
			this.reset();
		}
		if (sortType) {
			this.setActiveByUrl(sortType);
		}
		if (sortTime) {
			this.setActiveByUrl(sortTime);
		}
		if (category) {
			if (sortType || sortTime) {
				this.updateCateogires();
			}

			this.setActiveByUrl(this.urlHelper.category(category, sortType, sortTime));
		}
		if (month) {
			this.setActiveByUrl(this.urlHelper.archive(month));
		}
		if (year) {
			this.setActiveByUrl(this.urlHelper.archive(year, true));
		}
		if (searchQuery) {
			this.searchTermSubject.next(searchQuery);
			this.reset();
		}

		this.itemsSubject.next(this.items);
	}

	reset(): void {
		this.items = this.sidebarBuilder.build();
		this.itemsSubject.next(this.items);
	}

	private getByType(type: SidebarType): SidebarItem[] {
		return this.items.filter(i => i.type === type);
	}

	private setActiveByUrl(url: string): void {
		const item = this.items.find(i => i.url === url);
		if (item) {
			this.deselect(item.type);
			item.active = true;
		}
	}

	private getSortSegments(): string[] {
		const sortTypeUrl = this.items.find(x => x.type === SidebarType.Sort && x.active)!.url;
		const sortTimeUrl = this.items.find(x => x.type === SidebarType.Time && x.active)!.url;

		return [sortTypeUrl, sortTimeUrl].filter(x => !!x);
	}

	private updateCateogires(): void {
		const segments = this.getSortSegments();
		const categories = this.getByType(SidebarType.Category);

		for (const category of categories) {
			const baseSegments = category.url.split('/').slice(0, 3);
			category.url = this.urlHelper.url([...baseSegments, ...segments]);
		}
	}

	private deselect(type: SidebarType): void {
		this.getByType(type).forEach(x => (x.active = false));
		this.getByType(SidebarType.Category).forEach(x => (x.active = false));
		this.getByType(SidebarType.Year).forEach(x => (x.active = false));
		this.getByType(SidebarType.Month).forEach(x => (x.active = false));
	}
}
