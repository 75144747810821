import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RouterEventsService, RouteTag, AboutParams, TagService } from '@gamewaver/navigation';
import { MenuUser } from '@gamewaver/users';
import { LoginComponent, LoginDialogData, AuthService } from '@gamewaver/auth';
import { NotificationDialogService, NotificationsService } from '@gamewaver/notifications';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

export interface Menu {
	path: string;
	name: string;
	icon: string;
	route: string;
	children: Menu[];
}

export const MenuItems: Menu[] = [
	{
		name: 'about',
		route: AboutParams.About,
		children: [
			{ path: 'faq', name: 'faq' },
			{ path: 'team', name: 'team' },
			{ path: 'tos', name: 'tos' },
		],
	} as Menu,
];

@Component({
	selector: 'gw-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	@ViewChild(CdkOverlayOrigin, { read: ElementRef }) origin: ElementRef;

	menuItems = MenuItems;
	dialogOpen$: Observable<boolean>;
	notificationsCount$: Observable<number>;
	user$: Observable<MenuUser>;

	private tag: RouteTag;

	constructor(
		public dialog: MatDialog,
		private authService: AuthService,
		private routerEvents: RouterEventsService,
		private notificationsService: NotificationsService,
		private notificationDialogService: NotificationDialogService,
		private tagService: TagService,
	) {
		this.routerEvents.navigationStart$.subscribe(e => {
			this.tag = e.snapshot.parent?.firstChild?.data.tag;
			this.tagService.tag = this.tag;
		});

		this.user$ = this.authService.menuUser$;
		this.notificationsCount$ = this.notificationsService.notificationCount$;
		this.dialogOpen$ = this.notificationDialogService.opened$;
	}

	openLoginDialog(): void {
		this.dialog.open(LoginComponent, { data: { tag: this.tag } as LoginDialogData });
	}

	onLogout(): void {
		this.authService.logout(this.tag);
	}

	onNotification(): void {
		this.notificationDialogService.toggle(this.origin);
	}
}
