import { Injectable } from '@angular/core';
import { SnackbarService, SnackbarErrors, LoadingService, EnvironmentService, ItemsService } from '@gamewaver/core';
import { BehaviorSubject, EMPTY, iif, Observable, of, switchMap, takeUntil } from 'rxjs';
import { Notification, NotificationStatus } from './notification';
import { NotificationDialogService } from './notification-dialog.service';
import { NotificationsApiService } from './notifications.api.service';

@Injectable()
export class NotificationsService extends ItemsService<Notification> {
	private unreadCount = 0;
	private notificationCountSubject = new BehaviorSubject<number>(this.unreadCount);

	get notificationCount$(): Observable<number> {
		return this.notificationCountSubject.asObservable();
	}

	constructor(
		private notificationsApiService: NotificationsApiService,
		private loadingService: LoadingService,
		environmentService: EnvironmentService,
		private snackbarService: SnackbarService,
		private notificationDialogService: NotificationDialogService
	) {
		super(environmentService);

		this.notificationDialogService.opened$
			.pipe(
				takeUntil(this.notificationDialogService.destroyedEx$),
				switchMap(o => iif(() => !!o, of(o), EMPTY))
			)
			.subscribe(() => {
				this.clear();
				this.getMany(true);
				this.getCount();
			});
	}

	async getMany(fromDialog?: boolean): Promise<void> {
		if (this.noMoreItems && !fromDialog) {
			return;
		}

		try {
			this.loadingService.setNotificationsLoading();
			const notifications = (await this.notificationsApiService.findAll({ paging: this.paging })).result;
			this.items = this.items.concat(notifications.items);
			this.paging.skip = this.items.length;
			this.noMoreItems = this.items.length === notifications.total;
			this.itemsSubject.next(this.items);
		} catch ({ error }) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetNotifications);
		} finally {
			this.loadingService.setNotificationsLoading(false);
		}
	}

	async edit(cmd: NotificationStatus, id: number): Promise<void> {
		try {
			const notification = (await this.notificationsApiService.update(id, cmd)).result;
			const foundNotification = this.items.find(n => n.id === id)!;
			foundNotification.status = notification.status;
			this.decreaseCount();
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.EditNotification);
		}
	}

	async delete(id: number): Promise<void> {
		try {
			const deleted = (await this.notificationsApiService.delete(id)).result;
			this.deleteOrReplace(id);
			this.decreaseCount(deleted.status);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.DeleteNotification);
		}
	}

	async getCount(): Promise<void> {
		try {
			this.unreadCount = (await this.notificationsApiService.findCount()).result.count;
			this.notificationCountSubject.next(this.unreadCount);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetNotificationCount);
		}
	}

	private decreaseCount(status?: NotificationStatus): void {
		if (status === NotificationStatus.Unread) {
			this.unreadCount--;
			this.notificationCountSubject.next(this.unreadCount);
		}
	}
}
