import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from '@gamewaver/auth';
import { UserInfoContext, UserInfo, MentionDom } from '@gamewaver/shared';
import { UserCardService } from '@gamewaver/user-card/user-card.service';
import { RedirectHelperService, RouteTag, TagService, UrlHelperService } from '@gamewaver/navigation';
import { SidebarStateService } from '@gamewaver/sidebar';
import { ScrollPositionService, SnackbarService, TypedChange } from '@gamewaver/core';
import { User } from '@gamewaver/users';
import { PostViewModel, VoteType } from './models/post-view.model';
import { PostVoteCmd } from './models/post.models';
import { postVisibilities } from './models/post-consts';
import { PostsService } from './posts.service';

@Component({
	selector: 'gw-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnChanges {
	@Input() post: PostViewModel;
	@Input() user: User;
	@Output() editPost: EventEmitter<void> = new EventEmitter();

	voteType = VoteType;
	canEditOrDelete: boolean;
	visibilities = postVisibilities;
	deleteDisabled: boolean;
	url: string;

	get userInfo(): UserInfo {
		return {
			id: this.post.authorId,
			avatar: this.post.avatar,
			username: this.post.username,
			role: this.post.userRole,
		};
	}

	get userInfoContext(): UserInfoContext {
		if (this.tagService.isProfileTab) {
			return UserInfoContext.ProfilePost;
		}

		return UserInfoContext.Post;
	}

	get showComment(): boolean {
		return this.tagService.isProfileTab || this.tagService.tag === RouteTag.Home;
	}

	get showEdit(): boolean {
		return this.canEditOrDelete && this.tagService.tag === RouteTag.PostPage;
	}

	get isProfilePage(): boolean {
		return this.tagService.isProfileTab;
	}

	constructor(
		private router: Router,
		private clipboard: Clipboard,
		private snackbarService: SnackbarService,
		private scrollPositionService: ScrollPositionService,
		private postsService: PostsService,
		private authService: AuthService,
		private userCardService: UserCardService,
		private redirectHelper: RedirectHelperService,
		private urlHelper: UrlHelperService,
		private sidebarState: SidebarStateService,
		private tagService: TagService,
	) {}

	ngOnChanges(changes: TypedChange<PostComponent>): void {
		if (changes.user) {
			this.canEditOrDelete = this.authService.isOwn(this.post.authorId) || this.authService.isAdmin();
		}

		if (changes.post?.currentValue) {
			if (this.post.comments && !this.authService.isAdmin()) {
				this.deleteDisabled = true;
			}

			this.url = this.urlHelper.post(this.post.id);
		}
	}

	onEdit(): void {
		this.editPost.emit();
	}

	onDelete(): void {
		this.postsService.delete(this.post.id);
	}

	onCategory(): void {
		const url = this.urlHelper.category(this.post.category);

		if (url !== this.router.url) {
			this.sidebarState.reset();
			this.redirectHelper.goToCategory(this.post.category);
		}
	}

	onCopyLink(): void {
		this.clipboard.copy(window.location.origin + '/post/' + this.post.id);
		this.snackbarService.showInfo('Link Copied.');
	}

	onPostLink(): void {
		this.scrollPositionService.savePostsScrollPos();
	}

	onVote(voteType: VoteType): void {
		if (!this.user) {
			this.redirectHelper.goToLogin();
		}

		const cmd: PostVoteCmd = {
			postId: this.post.id,
			type: voteType,
		};

		switch (this.post.vote?.type) {
			// user already have vote and clicks to remove it
			case VoteType.Upvote:
			case VoteType.DownVote:
				this.postsService.unVote(this.post.vote.id);
				break;

			default:
				this.postsService.vote(cmd);
				break;
		}
	}

	onMention(data: MentionDom): void {
		this.userCardService.open(data);
	}
}
