import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export interface MentionDom {
	id: number,
	element: HTMLAnchorElement;
}
@Directive({
	selector: '[gwMention]',
})
export class MentionDirective {
	@Output() mentionClick: EventEmitter<MentionDom> = new EventEmitter<MentionDom>();

	@HostListener('click', ['$event'])
	click(event: PointerEvent): void {
		const target = (event.target as HTMLAnchorElement);
		const isMentionEl = target.attributes.getNamedItem('class')?.value === 'mention';
		const mentionId = target.attributes.getNamedItem('id')?.value;

		if (isMentionEl && mentionId) {
			this.mentionClick.emit({ id: +mentionId, element: target });
		}
	}
}
