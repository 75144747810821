import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { AuthApiService } from './auth.api.service';
import { EnvironmentService } from '@gamewaver/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthApiService, private environmentService: EnvironmentService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handle(request, next));
	}

	async handle(request: HttpRequest<any>, next: HttpHandler): Promise<any> {
		const token = this.authService.getToken();
		const isRenew = request.url.includes('renew');
		const isTokenExpired = token && Date.now() > token.savedAt + token.expiresIn * 1000;
		const isImgur = request.url.includes('imgur.com');

		// check if token is expired if yes we request a new one
		if (!isRenew && token && isTokenExpired && !isImgur) {
			const newToken = await this.authService.renewToken();
			this.authService.saveToken(newToken);
		}

		if (isImgur) {
			request = request.clone({ setHeaders: { Authorization: `Client-ID ${this.environmentService.uploadApiClientId}` } });
		} else if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: this.authService.getAuthorizationHeaderValue()!,
				},
			});
		}

		return lastValueFrom(next.handle(request));
	}
}
