import { SharedModule } from '@gamewaver/shared';
import { RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { SearchComponent } from './search.component';
import { SortByComponent } from './sort-by.component';
import { CategoryComponent } from './category.component';
import { ArchiveComponent } from './archive.component';
import { SidebarBuilderService } from './sidebar-builder.service';
import { SidebarStateService } from './sidebar-state.service';
import { NavigationModule } from '@gamewaver/navigation';

@NgModule({
	declarations: [SidebarComponent, SearchComponent, SortByComponent, CategoryComponent, ArchiveComponent],
	imports: [RouterModule, SharedModule, NavigationModule],
	exports: [SidebarComponent],
	providers: [SidebarBuilderService, SidebarStateService],
})
export class SidebarModule {
	static forRoot(): ModuleWithProviders<SidebarModule> {
		return {
			ngModule: SidebarModule,
			providers: [SidebarStateService],
		};
	}
}
