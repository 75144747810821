import { Component, HostBinding, Inject } from '@angular/core';
import { CARD_TOKEN, UserCardService } from './user-card.service';
import { Observable } from 'rxjs';
import { UserViewModel, UsersService } from '@gamewaver/users';

@Component({
	selector: 'gw-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
	@HostBinding('class') className = 'user-card';
	user$: Observable<UserViewModel | undefined>;

	constructor(@Inject(CARD_TOKEN) private userCardService: UserCardService, private usersService: UsersService) {
		this.user$ = this.usersService.item$;
	}

	onProfile(): void {
		this.userCardService.close();
	}
}
