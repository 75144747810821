import { ElementRef, Injectable } from '@angular/core';
import { OnDestroyCleanup, PortalPosition, PortalService } from '@gamewaver/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsComponent } from './notifications.component';

@Injectable()
export class NotificationDialogService extends OnDestroyCleanup {
	destroyedEx$ = this.destroyed$;

	private opened = false;
	private _opened = new BehaviorSubject<boolean>(this.opened);

	constructor(private portalService: PortalService<NotificationsComponent>) {
		super();
	}

	get opened$(): Observable<boolean> {
		return this._opened.asObservable();
	}

	toggle(origin?: ElementRef): void {
		this.opened = !this.opened;
		this._opened.next(this.opened);

		if (this.opened && origin) {
			this.portalService.open({
				component: NotificationsComponent,
				origin,
				disableScroll: true,
				offsetY: 60,
				position: PortalPosition.Left,
				beforeDismiss: () => {
					this.toggle();
				},
			});
		} else {
			this.portalService.close();
		}
	}
}
