import { IEnvironment } from '@gamewaver/core';

export const environment: IEnvironment = {
	production: true,
	apiUrl: 'https://api.gamewaver.com/',
	uploadApiUrl: 'https://api.imgur.com/3/image',
	uploadApiClientId: '7cb4e66dbbd970c',
	reCaptchaSiteKey: '6LdPhL8ZAAAAAKo752upfgxLGSUWE_QJtxvUjbQV',
	take: 10,
};
