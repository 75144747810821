import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
	private _uiLoading = new BehaviorSubject(false);
	private _notificationsLoading = new BehaviorSubject(false);

	uiLoading$ = this._uiLoading.asObservable();
	notificationsLoading$ = this._notificationsLoading.asObservable();

	setUILoading(loading: boolean = true): void {
		if (this._uiLoading.getValue() !== loading) {
			this._uiLoading.next(loading);
		}
	}

	setNotificationsLoading(loading: boolean = true): void {
		if (this._notificationsLoading.getValue() !== loading) {
			this._notificationsLoading.next(loading);
		}
	}
}
