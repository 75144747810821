import { Injectable } from '@angular/core';
import { PagedData, RequestParams, IResponse, HttpClientService } from '@gamewaver/core';
import { GetPostDto, PostCmd } from './models/post.models';

@Injectable({
	providedIn: 'root',
})
export class PostsApiService {
	BASE_URL = `posts`;

	constructor(private httpClient: HttpClientService) {}

	create(cmd: PostCmd): Promise<IResponse<GetPostDto>> {
		return this.httpClient.post<IResponse<GetPostDto>>(`${this.BASE_URL}`, cmd);
	}

	findAll(params?: RequestParams): Promise<IResponse<PagedData<GetPostDto>>> {
		return this.httpClient.get<IResponse<PagedData<GetPostDto>>>(`${this.BASE_URL}${this.httpClient.getParams(params)}`);
	}

	findOne(id: number): Promise<IResponse<GetPostDto>> {
		return this.httpClient.get<IResponse<GetPostDto>>(`${this.BASE_URL}/${id}`);
	}

	delete(id: number): Promise<IResponse<GetPostDto>> {
		return this.httpClient.delete<IResponse<GetPostDto>>(`${this.BASE_URL}/${id}`);
	}

	update(id: number, cmd: PostCmd): Promise<IResponse<GetPostDto>> {
		return this.httpClient.put<IResponse<GetPostDto>>(`${this.BASE_URL}/${id}`, cmd);
	}
}
