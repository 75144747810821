import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService, ItemsService, LoadingService, SnackbarErrors, SnackbarService } from '@gamewaver/core';
import { Mention } from '@gamewaver/add-item';
import { UsersApiService } from './users.api.service';
import { AuthService } from '../auth/auth.service';
import { UpdateUserCmd, UserViewModel } from '@gamewaver/users';

@Injectable({ providedIn: 'root' })
export class UsersService extends ItemsService<UserViewModel> {
	constructor(
		private usersApiService: UsersApiService,
		private loadingService: LoadingService,
		private router: Router,
		private authService: AuthService,
		environmentService: EnvironmentService,
		private snackbarService: SnackbarService
	) {
		super(environmentService);
	}

	async getOne(id: number, showLoading: boolean = true): Promise<void> {
		try {
			if (showLoading) {
				this.loadingService.setUILoading();
			}

			const user = (await this.usersApiService.findOne(id)).result;
			const mapped = this.authService.mapUser(user, !showLoading);
			this.itemSubject.next(mapped);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetUser);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async edit(cmd: UpdateUserCmd, id: number, isOwnProfile?: boolean): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const user = (await this.usersApiService.update(id, cmd)).result;
			const mapped = this.authService.mapUser(user);
			this.itemSubject.next(mapped);
			this.snackbarService.showInfo('Profile Edited Successfully');

			if (isOwnProfile) {
				this.authService.profile = mapped;
			}
			this.router.navigateByUrl(`/users/profile/${mapped.id}`);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.EditUser);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async findMentions(seachTerm: string): Promise<Mention[]> {
		try {
			return (await this.usersApiService.findAll({ filters: [{ username: seachTerm }] })).result.items.map(u => {
				return {
					id: u.id,
					image: u.avatar,
					name: u.username,
				} as Mention;
			});
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetUsersForMention);

			return Promise.resolve([]);
		}
	}
}
