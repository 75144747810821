<mat-card>
	<h3>SORT BY</h3>
	<mat-form-field appearance="fill">
		<mat-label>Type</mat-label>
		<mat-select [formControl]="selectedType" (selectionChange)="onType()">
			<mat-option [value]="item" *ngFor="let item of sorts">{{ item.label }}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field appearance="fill">
		<mat-label>Time</mat-label>
		<mat-select [formControl]="selectedTime" (selectionChange)="onTime()">
			<mat-option [value]="item" *ngFor="let item of times">{{ item.label }}</mat-option>
		</mat-select>
	</mat-form-field>
</mat-card>
