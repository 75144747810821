import { HomeViewModel } from '@gamewaver/home';
import { GetVoteDto } from './post.models';

export interface PostViewModel extends HomeViewModel {
	category: PostCategory;
	categoryLabel: string;
	userActionOnPost?: UserActionOnPost;
	comments: number;

	upvotes: number;
	downvotes: number;
	vote?: GetVoteDto;
	visibility: PostVisibility;
}

export enum UserActionOnPost {
	Posted = 'posted',
	Commented = 'commented',
	Voted = 'voted',
}

export enum VoteType {
	Upvote = 'upvote',
	DownVote = 'downvote',
}

export enum PostVisibility {
	PUBLIC = 'public',
	UNLISTED = 'unlisted',
	PRIVATE = 'private',
}

export enum PostCategory {
	IMAGE = 'image',
	VIDEO = 'video',
	BLOG_POST = 'blog_post',
	MEME = 'meme',
	NSFW = 'nsfw',
	OTHER = 'other',
}
