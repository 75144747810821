import { Component } from '@angular/core';
import { Dictionary } from '@gamewaver/core';
import { map, Observable } from 'rxjs';
import { SidebarStateService } from './sidebar-state.service';
import { SidebarItem, SidebarType } from './sidebar-view.models';

@Component({
	selector: 'gw-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']

})
export class SidebarComponent {
	dataObs: Observable<Dictionary<SidebarItem[]>>;
	searchTerm: Observable<string>;

	constructor(private sidebarState: SidebarStateService) {
		this.searchTerm = this.sidebarState.searchTerm$;
		this.dataObs = this.sidebarState.items$.pipe(
			map(i => {
				const sorts = i.filter(x => x.type === SidebarType.Sort);
				const times = i.filter(x => x.type === SidebarType.Time);
				const categories = i.filter(x => x.type === SidebarType.Category);
				const years = i.filter(x => x.type === SidebarType.Year);
				const months = i.filter(x => x.type === SidebarType.Month);

				return { sorts, times, categories, years, months };
			})
		);
	}

	onSelected(selected: SidebarItem): void {
		this.sidebarState.setActive(selected);
	}
}
