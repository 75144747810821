import { distinctUntilChanged, delayWhen, Observable, timer } from 'rxjs';
import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PostsService } from '@gamewaver/posts';
import { LoadingService } from '@gamewaver/core';

@Component({
	selector: 'gw-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	@Input() sidenav: MatSidenav;
	loadingBarVisible$: Observable<boolean>;

	constructor(private loadingService: LoadingService, public postsService: PostsService) {
		// delay for users to notice it
		this.loadingBarVisible$ = this.loadingService.uiLoading$.pipe(
			distinctUntilChanged(),
			delayWhen(loading => timer(loading ? 0 : 500))
		);
	}
}
