import { SharedModule } from '@gamewaver/shared';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, SecurityContext } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '@gamewaver/auth';
import { HeaderModule } from '@gamewaver/header';
import { CoreModule, markedOptionsFactory } from '@gamewaver/core';
import { SidebarModule } from './sidebar';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CoreModule,
		SharedModule,
		MarkdownModule.forRoot({
			sanitize: SecurityContext.NONE,
			markedOptions: {
				provide: MarkedOptions,
				useFactory: markedOptionsFactory,
			},
		}),
		HeaderModule,
		SidebarModule.forRoot(),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
