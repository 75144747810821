import { Component, Input, OnChanges, Output, EventEmitter, HostBinding } from '@angular/core';
import { UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { TypedChange } from '@gamewaver/core';

export interface Options {
	label: string;
	value?: string;
	icon?: string;
	description?: string;
}

export interface Selector {
	label: string;
	options: Record<string, Options>;
	required: boolean;
	name: string;
	value?: string;
	cssClass?: string;
}

@Component({
	selector: 'gw-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnChanges {
	@Input() model: Selector;
	selector: UntypedFormControl;
	@Output() selected: EventEmitter<string> = new EventEmitter();
	@Output() loadedSelector: EventEmitter<UntypedFormControl> = new EventEmitter();
	@HostBinding('class') className = 'selector';
	private validators: ValidatorFn[] = [];

	ngOnChanges(changes: TypedChange<SelectorComponent>): void {
		if (changes.model?.currentValue) {
			if (this.model.required) {
				this.validators.push(Validators.required);
			}

			this.selector = new UntypedFormControl(this.model.value, this.validators);
			this.loadedSelector.emit(this.selector);
		}
	}

	onSelectionChange(selectedItem: string): void {
		this.selected.emit(selectedItem);
	}
}
