import { TokenDto, LoginCmd, SignUpCmd, SentEmailDto, TokenLocal, ForgotPasswordCmd, ResetPasswordCmd } from '@gamewaver/auth';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClientService, IResponse } from '@gamewaver/core';
import { User } from '@gamewaver/users';

@Injectable({
	providedIn: 'root',
})
export class AuthApiService {
	BASE_URL = `auth`;
	accessToken = 'accessToken';

	constructor(private httpClient: HttpClientService) {}

	login(cmd: LoginCmd): Promise<TokenDto> {
		return this.httpClient.post<TokenDto>(`${this.BASE_URL}/login`, cmd);
	}

	register(cmd: SignUpCmd): Promise<SentEmailDto> {
		return this.httpClient.post<SentEmailDto>(`${this.BASE_URL}/signup`, cmd);
	}

	getUser(): Promise<IResponse<User>> {
		// will always be logged in as long as we call get user while logged in
		const token = this.getAuthorizationHeaderValue()!;
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', token);

		return this.httpClient.get<IResponse<User>>(`${this.BASE_URL}/profile`, { headers });
	}

	isLoggedIn(): boolean {
		return sessionStorage.getItem(this.accessToken) !== null || localStorage.getItem(this.accessToken) !== null;
	}

	logout(): void {
		sessionStorage.clear();
		localStorage.clear();
	}

	getAuthorizationHeaderValue(): string | undefined {
		if (!this.getToken()) {
			return;
		}

		return `Bearer ${this.getToken()!.accessToken}`;
	}

	getToken(): TokenLocal | undefined {
		const token = sessionStorage.getItem(this.accessToken) || localStorage.getItem(this.accessToken);

		if (!token) {
			return;
		}

		return JSON.parse(token);
	}

	saveToken(token: TokenDto, isSession?: boolean): void {
		const tempToken: TokenLocal = { ...token, savedAt: Date.now() };
		if (isSession) {
			sessionStorage.setItem(this.accessToken, JSON.stringify(tempToken));

			return;
		}
		localStorage.setItem(this.accessToken, JSON.stringify(tempToken));
	}

	renewToken(): Promise<TokenDto> {
		const token = this.getToken()!.accessToken;

		return this.httpClient.get<TokenDto>(`${this.BASE_URL}/renew/${token}`);
	}

	forgotPassword(cmd: ForgotPasswordCmd): Promise<IResponse<SentEmailDto>> {
		return this.httpClient.post<IResponse<SentEmailDto>>(`${this.BASE_URL}/forgot-password`, cmd);
	}

	resetPassword(cmd: ResetPasswordCmd): Promise<IResponse<string>> {
		return this.httpClient.post<IResponse<string>>(`${this.BASE_URL}/reset-password`, cmd);
	}
}
