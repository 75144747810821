import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CommentViewModel } from './comment.models';
import { User } from '@gamewaver/users';
import { UrlHelperService } from '@gamewaver/navigation';
import { CommentsService } from './comments.service';
import { MentionDom } from '@gamewaver/shared';
import { UserCardService } from '@gamewaver/user-card/user-card.service';
import { AuthService } from '@gamewaver/auth';
import { TypedChange } from '@gamewaver/core';

@Component({
	selector: 'gw-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnChanges {
	@Input() comment: CommentViewModel;
	@Input() user: User;
	@Input() postAuthorId: number;
	@Output() editComment: EventEmitter<number> = new EventEmitter();
	canEditOrDelete: boolean;

	get userProfileRoute(): string {
		return this.urlHelper.profile(this.comment.authorId);
	}

	constructor(
		private commentsService: CommentsService,
		private urlHelper: UrlHelperService,
		private userCardService: UserCardService,
		private authService: AuthService) {
	}

	ngOnChanges(changes: TypedChange<CommentComponent>): void {
		if (changes.user) {
			this.canEditOrDelete = this.authService.isOwn(this.comment.authorId) || this.authService.isAdmin();
		}
	}

	onEdit(): void {
		this.editComment.emit(this.comment.id);
	}

	onDelete(): void {
		this.commentsService.delete(this.comment.id);
	}

	onMention(data: MentionDom): void {
		this.userCardService.open(data);
	}
}
