<button
  mat-icon-button
  color="primary"
  class="format-text-button"
  (click)="openEmojiOverlay()"
  cdkOverlayOrigin
  matTooltip="Add emoji"
>
  <mat-icon>insert_emoticon</mat-icon>
</button>

<ng-template #templatePortalContent>
	<emoji-mart
	  (emojiClick)="addEmoji($event)"
	  title="Pick your emoji…"
	  emoji="point_up"
	  isNative="true"
	  class="emoji"
	  showSingleCategory="true"
	  cdkDrag
	></emoji-mart>
  </ng-template>


