import { SnackbarErrors } from '@gamewaver/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthApiService } from './auth.api.service';
import { LoadingService } from '../core/services/loading.service';
import { SnackbarService } from '../core/services/snackbar.service';
import { RegisterConfirmDialogComponent } from './register-confirm-dialog';
import { SignUpCmd, LoginCmd, ForgotPasswordCmd, ResetPasswordCmd, LoginDialogData } from '@gamewaver/auth';
import * as moment from 'moment';
import { RedirectHelperService, RouteTag, UrlHelperService } from '@gamewaver/navigation';
import { NotificationsService } from '@gamewaver/notifications';
import { UserViewModel, UserRole, User, MenuUser } from '@gamewaver/users';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private profileSubject = new BehaviorSubject<UserViewModel | undefined>(undefined);
	private _profile?: UserViewModel;
	private menuUserSubject = new BehaviorSubject<MenuUser>(this.getMenuUser());

	set profile(value: UserViewModel) {
		this._profile = value;
		this.profileSubject.next(this._profile);
	}

	get profile$(): Observable<UserViewModel | undefined> {
		return this.profileSubject.asObservable();
	}

	get menuUser$(): Observable<MenuUser> {
		return this.menuUserSubject;
	}

	constructor(
		private authApiService: AuthApiService,
		private loadingService: LoadingService,
		private dialog: MatDialog,
		private snackbarService: SnackbarService,
		private redirectHelper: RedirectHelperService,
		private urlHelper: UrlHelperService,
		private notificationsService: NotificationsService,
	) {}

	async register(cmd: SignUpCmd): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const sentEmailDto = await this.authApiService.register(cmd);
			this.redirectHelper.goToHome();
			this.dialog.open(RegisterConfirmDialogComponent, { data: sentEmailDto });
			this.snackbarService.showInfo('Registration Successfull');
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.Register);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async login(cmd: LoginCmd, data: LoginDialogData): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const accessToken = await this.authApiService.login(cmd);
			this.authApiService.saveToken(accessToken);
			await this.getProfile();

			if (data.isEmailConfirmed || data.tag === RouteTag.Register) {
				this.redirectHelper.goToHome();
			}
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.Login);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async getProfile(): Promise<void> {
		try {
			const profile = (await this.authApiService.getUser()).result;
			this.notificationsService.getCount();
			this.saveMenuUser(profile);
			this._profile = this.mapUser(profile);
			this.profileSubject.next(this._profile);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.GetUser);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async forgotPassword(cmd: ForgotPasswordCmd): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const sentEmailDto = (await this.authApiService.forgotPassword(cmd)).result;
			this.snackbarService.showInfo(sentEmailDto.message);
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.ForgotPassword);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	async resetPassword(cmd: ResetPasswordCmd): Promise<void> {
		try {
			this.loadingService.setUILoading();
			const message = (await this.authApiService.resetPassword(cmd)).result;
			this.snackbarService.showInfo(message);
			this.redirectHelper.goToLogin();
		} catch (error) {
			this.snackbarService.handleFailure(error, SnackbarErrors.ResetPassword);
		} finally {
			this.loadingService.setUILoading(false);
		}
	}

	logout(tag: RouteTag): void {
		this.authApiService.logout();
		this._profile = undefined;
		this.profileSubject.next(this._profile);
		this.menuUserSubject.next(undefined);

		if (tag === RouteTag.ProfileEdit) {
			this.redirectHelper.goToLogin();
		}
	}

	cancelEdit(id: number): void {
		this.redirectHelper.goToProfile(id);
	}

	isOwn(id: number): boolean {
		return this._profile?.id === id;
	}

	isAdmin(): boolean {
		return this._profile?.userRole === UserRole.ADMIN;
	}

	mapUser(user: User, fromMention?: boolean): UserViewModel {
		return {
			...user,
			joinedAt: `Joined ${moment(user.createdAt).format('MMMM DD, YYYY [at] hh:mm A')}`,
			userRole: user.role !== UserRole.USER ? user.role : '',
			daysRegistered: moment(moment.now()).diff(user.createdAt, 'days') + ' days',
			url: this.urlHelper.profile(user.id),
			fromMention: fromMention ? true : false,
		};
	}

	private saveMenuUser(user: User): void {
		const menuUser: MenuUser = { id: user.id, username: user.username };

		localStorage.setItem('menuUser', JSON.stringify(menuUser));
		this.menuUserSubject.next(menuUser);
	}

	private getMenuUser(): MenuUser {
		const user = localStorage.getItem('menuUser');

		if (user) {
			return JSON.parse(user);
		}
	}
}
