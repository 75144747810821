export enum SortType {
	Fresh = 'Fresh',
	Popular = 'Popular',
	Commented = 'Commented',
}

export enum SortTime {
	Days1 = '1 Day',
	Days7 = '7 Days',
	Days30 = '30 Days',
	All = 'All',
}

export enum SidebarType {
	Sort = 'Sort',
	Category = 'Category',
	Time = 'Time',
	Month = 'Month',
	Year = 'Year',
}

export interface SidebarItem {
	label: string;
	url: string;
	type: SidebarType;
	active?: boolean;
	subType?: SortType | SortTime;
	icon?: string;
	id?: string,
}
