<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a [routerLink]="['/']" (click)="postsService.refresh()">
      <img src="assets/images/logos/gamewaver_logo_square_cutted_min.png" alt="gamewaver_logo" />
    </a>

    <gw-menu class="menu-header"></gw-menu>

    <button mat-icon-button (click)="sidenav.toggle()" class="toggle-menu">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="loading">
  <mat-progress-bar *ngIf="loadingBarVisible$ | async" mode="query"></mat-progress-bar>
</div>
