import { Component, Inject } from '@angular/core';
import { Validators, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@gamewaver/core';
import { LoginCmd, LoginDialogData } from './auth.models';
import { AuthService } from './auth.service';

@Component({
	selector: 'gw-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	loginForm = new FormGroup({
		username: new FormControl(null, [Validators.required]),
		password: new FormControl(null, [Validators.required]),
		rememberMe: new FormControl(true, [Validators.required]),
	});

	constructor(
		private dialogRef: MatDialogRef<LoginComponent>,
		private snackbarService: SnackbarService,
		@Inject(MAT_DIALOG_DATA) public data: LoginDialogData,
		private authService: AuthService
	) {
		if (this.data.isEmailConfirmed) {
			this.snackbarService.showInfo(`Email Confirmed Successfully`);
		}
	}

	get username(): AbstractControl {
		return this.loginForm.get('username')!;
	}

	get password(): AbstractControl {
		return this.loginForm.get('password')!;
	}

	get rememberMe(): AbstractControl {
		return this.loginForm.get('rememberMe')!;
	}

	onAction(): void {
		this.dialogRef.close();
	}

	onLogin(): void {
		const loginCmd: LoginCmd = {
			username: this.username.value,
			password: this.password.value,
		};
		this.authService.login(loginCmd, this.data);
		this.onAction();
	}
}
