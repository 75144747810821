import { Injectable } from '@angular/core';
import { HttpClientService, IResponse } from '@gamewaver/core';
import { PostVoteCmd, GetVoteDto, GetVotesCountDto } from './models/post.models';

@Injectable({
	providedIn: 'root',
})
export class VotesApiService {
	BASE_URL = `votes`;

	constructor(private httpClient: HttpClientService) {}

	create(cmd: PostVoteCmd): Promise<IResponse<GetVoteDto>> {
		return this.httpClient.post<IResponse<GetVoteDto>>(`${this.BASE_URL}`, cmd);
	}

	delete(id: number): Promise<IResponse<GetVoteDto>> {
		return this.httpClient.delete<IResponse<GetVoteDto>>(`${this.BASE_URL}/${id}`);
	}

	findManyByPostId(ids: number[]): Promise<IResponse<GetVoteDto[]>> {
		return this.httpClient.get<IResponse<GetVoteDto[]>>(`${this.BASE_URL}?ids=${ids.join(',')}`);
	}

	findCountByPostId(ids: number[]): Promise<IResponse<GetVotesCountDto[]>> {
		return this.httpClient.get<IResponse<GetVotesCountDto[]>>(`${this.BASE_URL}/count?ids=${ids.join(',')}`);
	}
}
