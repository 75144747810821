import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { RequestParams } from '@gamewaver/core';
import { firstValueFrom } from 'rxjs';
import { keysIn } from 'lodash-es';

interface IRequestOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
	body?: any;
	absoluteUrl?: boolean;
}

interface IHttpClientService {
	get<T>(endPoint: string, options?: IRequestOptions): Promise<T>;
	post<T>(endPoint: string, params: object, options?: IRequestOptions): Promise<T>;
	put<T>(endPoint: string, params: object, options?: IRequestOptions): Promise<T>;
	delete<T>(endPoint: string, options?: IRequestOptions): Promise<T>;
}

@Injectable()
export class HttpClientService implements IHttpClientService {
	private url: string;

	constructor(private http: HttpClient, private environmentService: EnvironmentService) {
		this.url = this.environmentService.apiUrl;
	}

	get<T>(endPoint: string, params?: object, options?: IRequestOptions): Promise<T> {
		return firstValueFrom(this.http.get<T>(this.url + endPoint, this.SetHeaders(options)));
	}

	post<T>(endPoint: string, params: object, options?: IRequestOptions): Promise<T> {
		const url = options?.absoluteUrl ? endPoint : this.url + endPoint;

		return firstValueFrom(this.http.post<T>(url, params, this.SetHeaders(options)));
	}

	put<T>(endPoint: string, params: object, options?: IRequestOptions): Promise<T> {
		return firstValueFrom(this.http.put<T>(this.url + endPoint, params, this.SetHeaders(options)));
	}

	delete<T>(endPoint: string, options?: IRequestOptions): Promise<T> {
		return firstValueFrom(this.http.delete<T>(this.url + endPoint, this.SetHeaders(options)));
	}

	getParams(params?: RequestParams): string {
		if (!params) {
			return '';
		}

		const { paging, sorting, filters } = params;
		let httpParams = new HttpParams();

		if (paging) {
			httpParams = httpParams.append('skip', paging.skip).append('take', paging.take);
		}

		if (sorting) {
			if (sorting.sortDirection) {
				httpParams = httpParams.append('sortDirection', sorting.sortDirection);
			}

			httpParams = httpParams.append('sortBy', sorting.sortBy);
		}

		if (filters?.length) {
			for (const filter of filters) {
				const key = keysIn(filter)[0];
				httpParams = httpParams.append(key, filter[key]);
			}
		}

		return `?${httpParams.toString()}`;
	}

	private SetHeaders(options?: IRequestOptions): IRequestOptions {
		return { ...options } as any;
	}
}
