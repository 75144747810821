<mat-card class="archive">
	<h3>ARCHIVE</h3>
	<div class="title">{{ currentYear }}</div>

	<div class="months">
		<ng-container *ngFor="let item of months">
			<a [class.active]="item.active" [routerLink]="item.url" (click)="onClick(item)">{{ item.label }}</a>
		</ng-container>
	</div>

	<div class="title">ARCHIVES BY YEAR</div>

	<div class="years">
		<ng-container *ngFor="let item of years">
			<a [class.active]="item.active" [routerLink]="item.url" (click)="onClick(item)">{{ item.label }}</a>
		</ng-container>
	</div>
</mat-card>
