import { AuthService } from '@gamewaver/auth';
import { AddItem } from '@gamewaver/add-item';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, delay, Observable, takeUntil } from 'rxjs';
import { OnDestroyCleanup } from '@gamewaver/core';
import { UserViewModel } from '@gamewaver/users';
import { PostsService } from './posts.service';
import { CommentsService, CommentViewModel } from '@gamewaver/comments';
import { PostViewModel } from './models/post-view.model';

@Component({
	selector: 'gw-post-page',
	templateUrl: './post-page.component.html',
})
export class PostPageComponent extends OnDestroyCleanup implements OnInit, OnDestroy {
	post: PostViewModel;
	user?: UserViewModel;
	comments$: Observable<CommentViewModel[]>;
	editItem: AddItem;
	isEditPost: boolean;
	isEditComment: boolean;

	constructor(private commentsService: CommentsService, private postsService: PostsService, private authService: AuthService) {
		super();
		this.comments$ = this.commentsService.items$.pipe(delay(10));
		combineLatest([this.postsService.item$, this.authService.profile$])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([post, user]) => {
				this.post = post!;
				this.user = user;

				if (user) {
					this.initialize();
				}
			});
	}

	ngOnInit(): void {
		this.commentsService.getMany();
	}

	onEditPost(): void {
		this.isEditPost = true;
		this.editItem = {
			...this.editItem,
			isPost: true,
			maxLength: 5000,
			id: this.post.id,
			content: this.post.content,
			category: this.post.category,
			visibility: this.post.visibility,
		};
	}

	onEditComment(id: number): void {
		this.isEditComment = true;
		const comment = this.commentsService.startEdit(id);
		this.editItem = { ...this.editItem, id, content: comment.content };
	}

	onCancelEdit(): void {
		if (this.isEditComment) {
			this.commentsService.cancelEdit();
		}

		this.initialize();
	}

	onScrollDown(): void {
		if (this.isEditComment || this.isEditPost) {
			return;
		}

		this.commentsService.getMany();
	}

	private initialize(): void {
		this.isEditPost = false;
		this.isEditComment = false;
		this.editItem = {
			minLength: 3,
			maxLength: 1000,
			userId: this.user?.id,
			userAvatar: this.user?.avatar,
		};
	}

	onDestroy(): void {
		this.commentsService.clearComments();
	}
}
