<div class="avatar" *ngIf="contextInput !== context.ProfilePost">
  <img [src]="avatar"*ngIf="shouldShowAvatarImage else clickableAvatar;" (error)="onError()" />
</div>

<div class="info-container">
  <div class="name" *ngIf="contextInput === context.Profile || contextInput === context.Post">
    <span [matTooltip]="userInfo.joinedAt" *ngIf="contextInput === context.Profile else clickableName;">{{ userInfo.username }}</span>
    <span class="role" [ngClass]="userInfo.role" *ngIf="userInfo.role">{{ userInfo?.role | titlecase }}</span>
  </div>

  <ng-content></ng-content>
</div>

<ng-template #clickableName>
  <a [routerLink]="url">{{ userInfo.username }}</a>
</ng-template>

<ng-template #clickableAvatar>
  <a [routerLink]="url">
    <img [src]="avatar" alt="{{ userInfo.username }}'s avatar" (error)="onError()" />
  </a>
</ng-template>






