import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RedirectHelperService } from '@gamewaver/navigation';
import { SnackbarService } from '@gamewaver/core';
import { AuthApiService } from '@gamewaver/auth';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthApiService, private snackbarService: SnackbarService, private redirectHelper: RedirectHelperService) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.authService.isLoggedIn()) {
			this.redirectHelper.goToLogin();
			this.snackbarService.showError('The page you are trying to access requires logged-in user.');

			return false;
		}

		return true;
	}
}
