<div class="tab-group">
	<div class="tab-header">
		<gw-tabs (selectedTab)="activeTab = $event"></gw-tabs>
		<gw-toolbar *ngIf="showToolbar" (textFormatted)="onTextFormatted($event)"></gw-toolbar>
	</div>

	<div class="tab-body">
		<div class="tab-panel" [class.active]="activeTab === tabOption.Write">
			<mat-card class="content">
				<gw-user-info [userInfo]="userInfo" [contextInput]="userInfoContext.AddItem"></gw-user-info>
				<form [formGroup]="itemForm">
					<mat-form-field class="form-field" cdkOverlayOrigin>
						<textarea
							[cdkTextareaAutosize]="true"
							[cdkAutosizeMinRows]="2"
							matInput
							placeholder="{{ addItem.isPost ? 'Add a post...' : 'Write a comment...' }}"
							formControlName="content"
							#textArea
							(click)="onClickTextArea(textArea)"
							(mouseup)="onClickTextArea(textArea)"
							(keypress)="onKeyPress($event, textArea)"
							id="add_item_textarea"
						>
						</textarea>
						<mat-error *ngIf="content.invalid">{{ getErrorMessage() }}</mat-error>
					</mat-form-field>

					<div class="actions" *ngIf="showActions">
						<div class="selectors">
							<gw-selector
								[model]="categoryData"
								*ngIf="addItem.isPost"
								(loadedSelector)="onLoadedSelecotor($event, categoryData.name)"
							></gw-selector>
							<gw-selector
								[model]="visibilityData"
								*ngIf="addItem.isPost"
								(loadedSelector)="onLoadedSelecotor($event, visibilityData.name)"
							></gw-selector>
						</div>
						<div class="buttons">
							<div class="icons">
								<gw-upload (imageLink)="onUpload($event)"></gw-upload>
								<gw-emoji (emoji)="onAddedEmoji($event)" #emoji></gw-emoji>
								<a
									mat-icon-button
									href="https://guides.github.com/features/mastering-markdown/"
									target="_blank"
									matTooltip="Styling with Markdown is supported"
								>
									<fa-icon [icon]="markdown"></fa-icon>
								</a>
							</div>
							<div class="submit">
								<button mat-stroked-button color="accent" class="cancel" (click)="onCancel()">Cancel</button>
								<button mat-raised-button color="primary" [disabled]="itemForm.invalid" (click)="onSubmit()">
									{{ addItem.content ? 'SAVE' : 'SUBMIT' }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</mat-card>
		</div>
		<div class="tab-panel" [class.active]="activeTab === tabOption.Preview">
			<mat-card class="preview">
				<markdown
					[data]="content.value"
					gwMention
					(mentionClick)="onMention($event)"
					*ngIf="content.value; else noPreview"
					ngPreserveWhitespaces
				></markdown>
			</mat-card>
		</div>
	</div>
</div>

<ng-template #noPreview>Nothing to preview</ng-template>
