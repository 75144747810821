import { PostViewModel } from './models/post-view.model';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SidebarStateService } from '@gamewaver/sidebar';
import { PostsService } from './posts.service';
import { CommentsService } from '@gamewaver/comments';

@Injectable()
export class PostPageResolver implements Resolve<PostViewModel | undefined> {
	constructor(private postsService: PostsService, private commentsService: CommentsService, private sidebarState: SidebarStateService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<PostViewModel | undefined> {
		const postId = +route.params.id;
		this.postsService.getOne(postId);
		this.commentsService.postId = postId;
		this.sidebarState.reset();

		return of(undefined);
	}
}
