import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// function that returns `MarkedOptions` with renderer override https://github.com/jfcere/ngx-markdown#markedoptions
export function markedOptionsFactory(): MarkedOptions {
	const renderer = new MarkedRenderer();

	renderer.list = (text: string): string => {
		if (new RegExp('<input').test(text)) {
			return `<ul class="checkbox-ul">${text}</ul>`;
		}

		return `<ul>${text}</ul>`;
	};

	renderer.listitem = (text: string): string => {
		if (new RegExp('<input').test(text)) {
			return `<li class="checkbox-li">${text}</li>`;
		}

		return `<li>${text}</li>`;
	};

	renderer.link = (href: string | null, title: string, text?: string): string => {
		const mentionRegex = new RegExp(/<?@([0-9]{1,11})>?/, 'gm');
		const match = mentionRegex.exec(href ?? '');
		if (match) {
			return `<a class="mention" id=${match[1]}>${text}</a>`;
		}

		return `<a href="${href}">${text}</a>`;
	};

	return {
		renderer: renderer,
		gfm: true,
		breaks: false,
		pedantic: false,
		smartLists: true,
		smartypants: false,
	};
}
