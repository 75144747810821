<mat-card>
	<form class="search-form">
		<mat-form-field class="form-field">
			<input matInput placeholder="Search..." [formControl]="searchTermControl" />
			<mat-icon matRipple matSuffix *ngIf="searchTermControl.value" (click)="onClear()">close</mat-icon>
			<mat-icon matRipple matSuffix *ngIf="!searchTermControl.value" (click)="onSubmit($event)">search</mat-icon>
		</mat-form-field>

		<div class="submit-wrapper">
			<button mat-raised-button color="primary" class="form-button" [disabled]="!searchTermControl.value" (click)="onSubmit($event)"></button>
		</div>
	</form>
</mat-card>
