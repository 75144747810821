<mat-sidenav-container (backdropClick)="close()">
  <mat-sidenav #sidenav (keydown.escape)="close()" disableClose class="sidenav">
    <div>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <gw-menu></gw-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <gw-header [sidenav]="sidenav"></gw-header>
    <main [class]="cssClass">
      <router-outlet></router-outlet>
      <gw-goto-top></gw-goto-top>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
