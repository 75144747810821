import { ComponentType, ConnectedPosition, FlexibleConnectedPositionStrategyOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector, TemplateRef, ViewContainerRef } from '@angular/core';

export enum PortalPosition {
	Right,
	Bottom,
	Left,
}

export interface PortalConfig<T> {
	component?: ComponentType<T>;
	origin: FlexibleConnectedPositionStrategyOrigin;
	position?: PortalPosition;
	disableScroll?: boolean;
	margin?: number;
	offsetY?: number;
	offsetX?: number;
	templateRef?: TemplateRef<unknown>;
	injector?: Injector;
	panelClass?: string,
	hostClass?: string,
	beforeDismiss?: () => void;
}

// does not get provided in root for some reason
@Injectable()
export class PortalService<T> {
	viewContainerRef: ViewContainerRef;

	private overlayRef: OverlayRef;
	private positions: Record<PortalPosition, ConnectedPosition> = {
		[PortalPosition.Right]: {
			originX: 'end',
			originY: 'center',
			overlayX: 'start',
			overlayY: 'center',
		},
		[PortalPosition.Bottom]: {
			originX: 'center',
			originY: 'center',
			overlayX: 'center',
			overlayY: 'center',
		},
		[PortalPosition.Left]: {
			originX: 'center',
			originY: 'center',
			overlayX: 'end',
			overlayY: 'center',
		},
	};

	constructor(private overlay: Overlay) {}

	open(config: PortalConfig<T>): void {
		let portal: Portal<any> | undefined;

		if (config.component) {
			portal = new ComponentPortal(config.component, null, config.injector);
		} else if (config.templateRef) {
			portal = new TemplatePortal(config.templateRef, this.viewContainerRef, null, config.injector);
		}

		this.overlayRef = this.overlay.create(this.getConfig(config));
		this.overlayRef.addPanelClass(config.panelClass ?? '');
		if (config.hostClass) {
			this.overlayRef.hostElement.classList.add(config.hostClass);
		}

		this.overlayRef.attach(portal);
		this.overlayRef.backdropClick().subscribe(() => {
			if (config.beforeDismiss) {
				config.beforeDismiss();
			}
			this.close();
		});
	}

	close(): void {
		this.overlayRef?.dispose();
	}

	private getConfig(config: PortalConfig<T>): OverlayConfig {
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(config.origin)
			.withPositions([this.positions[config.position ?? PortalPosition.Bottom]])
			.withFlexibleDimensions(true)
			.withPush(true)
			.withLockedPosition(true)
			.withViewportMargin(config.margin || 0)
			.withDefaultOffsetY(config.offsetY || 0)
			.withDefaultOffsetX(config.offsetX || 0);

		return new OverlayConfig({
			positionStrategy,
			backdropClass: 'backdrop',
			hasBackdrop: true,
			height: 'auto',
			minWidth: config.origin instanceof ElementRef ? config.origin.nativeElement.clientWidth : undefined,
			scrollStrategy: config.disableScroll ? this.overlay.scrollStrategies.block() : undefined,
		});
	}
}
