import { Injectable } from '@angular/core';
import { PagedData, RequestParams, IResponse, HttpClientService } from '@gamewaver/core';
import { Notification, NotificationCount, NotificationStatus } from '@gamewaver/notifications';

@Injectable()
export class NotificationsApiService {
	BASE_URL = `notifications`;

	constructor(private httpClient: HttpClientService) {}

	findAll(params?: RequestParams): Promise<IResponse<PagedData<Notification>>> {
		return this.httpClient.get<IResponse<PagedData<Notification>>>(`${this.BASE_URL}${this.httpClient.getParams(params)}`);
	}

	update(id: number, status: NotificationStatus): Promise<IResponse<Notification>> {
		return this.httpClient.put<IResponse<Notification>>(`${this.BASE_URL}/${id}`, { status });
	}

	delete(id: number): Promise<IResponse<Notification>> {
		return this.httpClient.delete<IResponse<Notification>>(`${this.BASE_URL}/${id}`);
	}

	findCount(): Promise<IResponse<NotificationCount>> {
		return this.httpClient.get<IResponse<NotificationCount>>(`${this.BASE_URL}/count`);
	}
}
