import { BehaviorSubject, Observable } from 'rxjs';
import { DataEntity, EnvironmentService, Paging, RequestParams } from '@gamewaver/core';
import { isNumber } from 'lodash-es';

export abstract class ItemsService<T extends Pick<DataEntity, 'id'>> {
	protected items: T[] = [];
	protected itemsSubject = new BehaviorSubject<T[]>(this.items);
	protected itemSubject = new BehaviorSubject<T | undefined>(undefined);
	protected noMoreItems: boolean;
	protected requestParams: RequestParams;
	protected paging: Paging = {
		skip: 0,
		take: 10,
	};

	get items$(): Observable<T[]> {
		return this.itemsSubject.asObservable();
	}

	get item$(): Observable<T | undefined> {
		return this.itemSubject.asObservable();
	}

	constructor(private environmentService: EnvironmentService) {
		this.paging.take = this.environmentService.take;
	}

	protected clear(): void {
		this.items = [];
		this.noMoreItems = false;
		this.paging.skip = 0;
		this.itemsSubject.next(this.items);
	}

	protected deleteOrReplace(id: number, newItem?: T, position?: number): void {
		const found = this.items.find(i => i.id === id);
		if (found) {
			const i = this.items.indexOf(found);

			if (newItem) {
				this.items.splice(i, 0, newItem);
			} else {
				this.items.splice(i, 1);
			}
		} else if (!found && isNumber(position) && newItem) {
			this.items.splice(position, 0, newItem);
		}

		this.itemsSubject.next(this.items);
	}
}
