import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarService } from './services/snackbar.service';
import { ScrollPositionService } from './services/scroll-position.service';
import { PortalService } from './services/portal.service';
import { LoadingService } from './services/loading.service';
import { EnvironmentService } from './services/environment.service';
import { HttpClientService } from './services/http-client.service';
import { GotoTopComponent } from './goto-top.component';
import { MatIconModule } from '@angular/material/icon';
import { NotFoundComponent } from './not-found.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [GotoTopComponent, NotFoundComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		RouterModule,
	],
	providers: [SnackbarService, ScrollPositionService, PortalService, LoadingService, EnvironmentService, HttpClientService],
	exports: [GotoTopComponent, NotFoundComponent]
})
export class CoreModule {}
